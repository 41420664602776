import React, { Fragment } from "react";
import Footer from "../../component/Other/Footer";
import Head from "../../component/Page/Head";
import SecBanner from "../../component/Page/SecBanner";
import SliderTeam from "../../component/Slider/SliderTeam";
import SliderTestimoial from "../../component/Slider/SliderTestimoial";

const About = () => {
  return (
    <Fragment>
      <div className="overflow-hidden">
        <div className="bg__blue-2">
          <Head title={"About Realm Riches"} />
        </div>
        <img
          src="./../images/Subtract.png"
          className="w-100 img-cover"
          alt=""
        />
        <section>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 position__initial">
                <img
                  src="./../images/asiangeneralchest.png"
                  alt=""
                  class="images__about-left-first"
                />
              </div>
              <div className="col-md-6 my-auto">
                <h2 className="semi-bold font__size--72 text__70-1024 text__70-md text__70-sm mb-4">
                  Welcome to Realm Riches
                </h2>
                <p className="medium font__size--12 text__12-1024 color__gray-1 lh-2">
                  Welcome to Realm Riches, your go-to hub for gamers of all
                  ages! Explore the latest and greatest games, and connect with
                  a community of like-minded players who share your passion.
                  Whether you’re discovering new titles or looking to make new
                  friends and rivals, Realm Riches has something for everyone.
                  Plus, with our strict code of conduct, you can enjoy a safe,
                  welcoming environment where the focus is always on having fun.
                </p>
                <a
                  href="#!"
                  className="d-inline-block semi-bold font__size--16 text__16-1024 btn btn__purple px-4 color__white shadow ml-auto text-capitalize rounded__12"
                >
                  <div className="d-flex align-items-center">
                    <img src="./../images/Game.png" className="mr-2" alt="" />
                    Play Free
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="text-center mb-5">
              <br className="d-none d-md-block" />
              <h2 className="semi-bold font__size--48 text__48-1024 text__48-md text__48-sm text__48-xx">
                Realm Riches is the best adventure{" "}
                <br className="d-none d-md-block" /> games platform?
              </h2>
            </div>

            <div className="row">
              <div className="col-md-4 mb-4 mb-md-0">
                <div className="wrapper__card-game">
                  <div className="icon mb-5">
                    <img src="./../images/Send.png" alt="" />
                  </div>
                  <h4 className="semi-bold font__size--18 text__18-1024">
                    Challenging Levels
                  </h4>
                  <p className="medium font__size--12 text__12-1024 lh-2 color__gray-1">
                    Play the challenging levels in the realms and progress with
                    wealth and experience. Build your empire to conquer more
                    levels, raid opposing threats to your own realm and build a
                    mighty empire.{" "}
                  </p>
                </div>
              </div>
              <div className="col-md-4 mb-4 mb-md-0">
                <div className="wrapper__card-game">
                  <div className="icon mb-5">
                    <img src="./../images/Star.png" alt="" />
                  </div>
                  <h4 className="semi-bold font__size--18 text__18-1024">
                    Global Leaderboard
                  </h4>
                  <p className="medium font__size--12 text__12-1024 lh-2 color__gray-1">
                    Compete against friends and foes on the global leaderboard,
                    climb the ranks, unlock special prizes, and discover a
                    hidden realm!{" "}
                  </p>
                </div>
              </div>
              <div className="col-md-4 mb-4 mb-md-0">
                <div className="wrapper__card-game">
                  <div className="icon mb-5">
                    <img src="./../images/Gamesd.png" alt="" />
                  </div>
                  <h4 className="semi-bold font__size--18 text__18-1024">
                    Free to play & free to win
                  </h4>
                  <p className="medium font__size--12 text__12-1024 lh-2 color__gray-1">
                    Experience the thrill of gaming without the price tag—dive
                    into top games where you can play and win for free, no
                    strings attached!{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row">
              <div className="col-md-6 mb-4 my-md-auto">
                <img
                  src="./../images/tabletgameplay.png"
                  style={{ maxWidth: "none", width: "700px" }}
                  alt=""
                />
              </div>
              <div className="col-md-6 my-auto">
                <h2 className="semi-bold font__size--72 text__70-1024 text__70-md text__70-sm mb-4">
                  Raid using your tablet
                </h2>
                <p className="medium font__size--12 text__12-1024 color__gray-1 lh-2">
                  Raid using your tablet or view the realm on a bigger screen to
                  fully immerse yourself in the environment. Explore the vast
                  world of Realm Riches, connect with fellow gamers, and enjoy
                  the ultimate gaming experience whether you’re on the go or at
                  home. With a diverse selection of games and a safe, welcoming
                  community, Realm Riches is where adventure awaits on every
                  device!
                </p>
                <a
                  href="#!"
                  className="d-inline-block semi-bold font__size--16 text__16-1024 btn btn__purple px-4 color__white shadow ml-auto text-capitalize rounded__12"
                >
                  <div className="d-flex align-items-center">
                    <img src="./../images/Game.png" className="mr-2" alt="" />
                    Play Free
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="position-relative">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 order-lg-1 order-12">
                <h2 className="semi-bold font__size--64 text__65-1024 text__65-md text__65-sm mb-4">
                  Choose the realm that’s right for you!
                </h2>
                <p className="medium font__size--12 text__12-1024 color__gray-1 lh-2">
                  Experience the excitement of exploring a vast world filled
                  with interesting characters, challenging puzzles and thrilling
                  battles. Conquer the obstacles and uncover the secrets of
                  Realm Riches – the ultimate gaming experience!
                </p>

                <a
                  href="#!"
                  className="d-inline-block semi-bold font__size--16 text__16-1024 btn btn__purple px-4 color__white shadow ml-auto text-capitalize rounded__12"
                >
                  <div className="d-flex align-items-center">
                    <img src="./../images/Game.png" className="mr-2" alt="" />
                    Play Free
                  </div>
                </a>
              </div>
              <div className="col-lg-6 order-1 order-lg-12 position__initial">
                <img
                  src="./../images/MobileGameplay.png"
                  className="images__about-right"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="text-center mb-5">
            <h2 className="semi-bold font__size--48 text__48-1024 text__48-md text__48-sm text__48-xx">
              Meet our team
            </h2>
          </div>

          <SliderTeam />
        </section>

        <section>
          <div className="container">
            <div className="text-center mb-5">
              <h2 className="semi-bold font__size--48 text__48-1024 text__48-md text__48-sm text__48-xx">
                What people have said about Realm Riches!
              </h2>
            </div>

            <SliderTestimoial />
          </div>
        </section>

        <SecBanner />

        <Footer />
      </div>
    </Fragment>
  );
};

export default About;
