import React, { Fragment, useRef, useState } from "react";
import Slider from "react-slick";
import Footer from "../../component/Other/Footer";

const Characters = () => {
  const charcters = [
    "./../images/ThumbKonsta.png",
    "./../images/ThumbCora.png",
    "./../images/ThumbFreya.png",
    "./../images/ThumbBjorn.png",
    "./../images/ThumbHamza.png",
    "./../images/ThumbMuna.png",
    "./../images/ThumRamsey.png",
    "./../images/ThumDina.png",
    "./../images/ThumHua.png",
    "./../images/ThumMing.png",
    "./../images/ThumAidan.png",
    "./../images/ThumAisha.png",
    "./../images/ThumAlani.png",
    "./../images/ThumLouka.png",
  ];

  const charctersBig = [
    "./../images/GeneralKonsta.png",
    "./../images/GeneralKora.png",
    "./../images/GeneralFreya.png",
    "./../images/GeneralBjorn.png",
    "./../images/GeneralHamza.png",
    "./../images/GeneralMuna.png",
    "./../images/GeneralRamsey.png",
    "./../images/GeneralDina.png",
    "./../images/GeneralHua.png",
    "./../images/GeneralMing.png",
    "./../images/GeneralAidan.png",
    "./../images/GeneralAisha.png",
    "./../images/GeneralAlani.png",
    "./../images/GeneralLouka.png",
  ];

  const about = [
    {
      bg: "./../images/HeroKonsta.png",
      profile: "./../images/ThumbKonsta.png",
      name: "General Konsta",
      desc: "General Konsta, a stalwart leader of ancient realms, is known for his unyielding strength and tactical prowess on the battlefield. His ability to inspire and command his troops is legendary, making him a formidable opponent in any skirmish.  ",
      power: [
        "Strength 80",
        "Skill 75",
        "Speed 50",
        "Luck 75",
        "Intelligence 65",
      ],
    },
    {
      bg: "./../images/HeroCora.png",
      profile: "./../images/ThumbCora.png",
      name: "General Cora",
      desc: "General Cora is a fierce and determined warrior known for her sharp intellect and unparalleled agility in combat. Unlike her counterpart, General Konsta, Freya excels in quick, decisive strikes and clever battle strategies.",
      power: [
        "Strength 70",
        "Skill 85",
        "Speed 80",
        "Luck 65",
        "Intelligence 65",
      ],
    },
    {
      bg: "./../images/HeroFreya.png",
      profile: "./../images/ThumbFreya.png",
      name: "General Freya",
      desc: "General Freya is a bold and formidable warrior, renowned for her unmatched strength and fierce combat skills. Hailing from the rugged northern realms, Freya embodies the spirit of a true Viking leader. With her double-edged axe in hand and her battle-hardened armor adorned with symbols of power, she strikes fear into the hearts of her enemies. ",
      power: [
        "Strength 90",
        "Skill 80",
        "Speed 70",
        "Luck 75",
        "Intelligence 70",
      ],
    },
    {
      bg: "./../images/HeroBjorn.png",
      profile: "./../images/ThumbBjorn.png",
      name: "General Björn",
      desc: "General Bjorn is a powerful and fearless leader from the Norse realm, embodying the true spirit of a Viking warrior. Known for his immense physical strength and unyielding determination, Bjorn charges into battle with an unstoppable force, his war cries echoing across the battlefield. His massive sword, coupled with his robust frame and golden beard, makes him an imposing figure that few dare to challenge. ",
      power: [
        "Strength 95",
        "Skill 70",
        "Speed 60",
        "Luck 70",
        "Intelligence 60",
      ],
    },
    {
      bg: "./../images/HeroHamza.png",
      profile: "./../images/ThumbHamza.png",
      name: "General Hamza",
      desc: "General Hamza is a charismatic and strategic leader from the Arab realm, known for his exceptional tactical skills and unwavering courage. With his signature curved scimitar in hand, Hamza is both a skilled swordsman and a master tactician, able to outthink and outmaneuver his opponents with ease. ",
      power: [
        "Strength 75",
        "Skill 85",
        "Speed 80",
        "Luck 70",
        "Intelligence 80",
      ],
    },
    {
      bg: "./../images/HeroMuna.png",
      profile: "./../images/ThumbMuna.png",
      name: "General Muna",
      desc: "General Muna is a brilliant and fearless leader from the Arab realm, known for her keen strategic mind and unmatched skill in combat. Clad in her distinctive blue and gold armor, Muna is a master of swordsmanship, wielding her scimitar with both grace and lethal precision. Her ability to stay calm under pressure and her quick thinking make her a formidable opponent on the battlefield. ",
      power: [
        "Strength 70",
        "Skill 90",
        "Speed 75",
        "Luck 65",
        "Intelligence 90",
      ],
    },
    {
      bg: "./../images/HeroRamsey.png",
      profile: "./../images/ThumbRamsey.png",
      name: "General Ramsey",
      desc: "General Ramsey is the charismatic and bold leader of the Egyptian realm, known for his quick wit and tactical ingenuity. Clad in the regal attire of an ancient pharaoh, Ramsey embodies the power and authority of Egypt's storied past. His curved sword, always at the ready, is a symbol of his prowess in combat and his readiness to defend his realm at a moment’s notice.",
      power: [
        "Strength 80",
        "Skill 75",
        "Speed 50",
        "Luck 60",
        "Intelligence 65",
      ],
    },
    {
      bg: "./../images/HeroDina.png",
      profile: "./../images/ThumbDina.png",
      name: "General Dina",
      desc: "General Dina is the fierce and commanding leader of the Egyptian realm, renowned for her tactical brilliance and unshakable resolve. Clad in the ornate armor of her ancestors, Dina is a warrior of both strength and intellect, wielding her sword and shield with precision and grace. She is deeply connected to the rich history and traditions of Egypt, drawing inspiration from the legends of great pharaohs and warriors before her.",
      power: [
        "Strength 80",
        "Skill 85",
        "Speed 70",
        "Luck 75",
        "Intelligence 85",
      ],
    },
    {
      bg: "./../images/HeroHua.png",
      profile: "./../images/ThumbHua.png",
      name: "General Hua",
      desc: "General Hua is a revered and cunning leader from the Asian realm, known for her exceptional martial arts skills and unmatched strategic brilliance. Dressed in her ornate armor, Hua carries a katana that she wields with deadly precision and elegance. Her disciplined approach to both training and combat has earned her a reputation as one of the finest warriors of her time.",
      power: [
        "Strength 75",
        "Skill 90",
        "Speed 80",
        "Luck 70",
        "Intelligence 85",
      ],
    },
    {
      bg: "./../images/HeroMing.png",
      profile: "./../images/ThumbMing.png",
      name: "General Ming",
      desc: "General Ming is a revered and disciplined leader from the Asian realm, known for his unwavering courage and mastery of traditional warfare techniques. Adorned in elaborate armor that reflects the heritage of his ancestors, Ming wields a spear with precision and authority, complemented by his trusty shield. His approach to battle is methodical and rooted in centuries-old martial traditions, making him a formidable opponent on the battlefield.",
      power: [
        "Strength 80",
        "Skill 85",
        "Speed 75",
        "Luck 70",
        "Intelligence 80",
      ],
    },
    {
      bg: "./../images/HeroAidan.png",
      profile: "./../images/ThumbAidan.png",
      name: "General Ai-dan",
      desc: "General Ai-dan is the cutting-edge leader of the Techno realm, a realm where advanced robotics and artificial intelligence converge to create the ultimate warrior. With a body made of the most sophisticated materials and equipped with state-of-the-art technology, Ai-dan is the epitome of technological prowess. His design is optimised for both defence and offence, allowing him to adapt to any situation on the battlefield.",
      power: [
        "Strength 90",
        "Skill 95",
        "Speed 85",
        "Luck 70",
        "Intelligence 95",
      ],
    },
    {
      bg: "./../images/HeroAisha.png",
      profile: "./../images/ThumbAisha.png",
      name: "General Ai-sha",
      desc: "General Ai-sha is the innovative and dynamic leader of the Techno realm, a futuristic world where technology and warfare merge seamlessly. Clad in advanced armor equipped with cutting-edge tech, Ai-sha wields a powerful hammer that channels energy, making her a formidable force in battle. Her strength lies not only in her combat skills but also in her ability to adapt and innovate, using the latest advancements in technology to outmanoeuvre her enemies. ",
      power: [
        "Strength 85",
        "Skill 90",
        "Speed 80",
        "Luck 70",
        "Intelligence 90",
      ],
    },
    {
      bg: "./../images/HeroAlani.png",
      profile: "./../images/ThumbAlani.png",
      name: "General Alani",
      desc: "General Alani is the vibrant and resourceful leader of the Tiki realm, known for her deep connection to nature and her people’s rich traditions. With her distinctive attire adorned with leaves and flowers, she carries the spirit of the island in both her appearance and her heart. She mastered rhythmic combat, using her drum and staff not only to rally her troops but also to channel the elemental forces of the earth. ",
      power: [
        "Strength 70",
        "Skill 80",
        "Speed 75",
        "Luck 85",
        "Intelligence 75",
      ],
    },
    {
      bg: "./../images/HeroLouka.png",
      profile: "./../images/ThumbLouka.png",
      name: "General Louka",
      desc: "General Louka is the vibrant and spirited leader of the Tiki realm, a realm rich in culture and traditions. Adorned in colorful feathers and tribal markings, Louka is a master of both strategy and ritualistic warfare. His weapon of choice, a ceremonial spear, is not just a tool of war but also a symbol of his deep connection to the spiritual forces that guide his people.",
      power: [
        "Strength 75",
        "Skill 80",
        "Speed 75",
        "Luck 85",
        "Intelligence 70",
      ],
    },
  ];

  const [character, setCharacter] = useState(0);
  const [characterBig, setCharacterBig] = useState(0);
  const [characterAbout, setCharacterAbout] = useState(0);

  const onChangeClick = (e) => {
    setCharacter(e);
    setCharacterBig(e);
    setCharacterAbout(e);
  };

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    centerMode: true,
    slidesToShow: 5,
    slidesToScroll: 5,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1030,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const slider1 = useRef(null);

  const previous = () => {
    slider1.current.slickNext();
  };

  const next = () => {
    slider1.current.slickPrev();
  };

  return (
    <Fragment>
      <div className="overflow-hidden">
        <div className="bg__blue-2">
          <section className="position-relative">
            <div className="d-none d-lg-block">
              {charctersBig.map((obj, i) => {
                if (i == characterBig) {
                  return (
                    <img src={obj} className="images__character-side" alt="" />
                  );
                }
              })}
            </div>

            <div className="container position-relative z-2">
              <div className="row">
                <div className="col-lg-5 z-2"></div>
                <div className="col-lg-7 z-2">
                  <div className="mb-5">
                    <div className="wrapper__offsite-character">
                      <div className="auto">
                        <div className="wrapper__characters-lits">
                          <Slider
                            ref={slider1}
                            {...settings}
                            className="wrapper__slider-characters-wrap"
                          >
                            {about.map((obj, i) => {
                              return (
                                <div className="items">
                                  <div
                                    className={
                                      "position-relative wrapper__images-icon text-center pointer " +
                                      (character == i ? "active" : "")
                                    }
                                    onClick={() => onChangeClick(i)}
                                  >
                                    <img src={obj.profile} alt="" />
                                    {/*<div className="dot mx-auto mt-3"></div>*/}
                                  </div>
                                </div>
                              );
                            })}
                          </Slider>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="d-block d-lg-none">
                    {charctersBig.map((obj, i) => {
                      if (i == characterBig) {
                        return (
                          <img
                            src={obj}
                            className="images__character-side"
                            alt=""
                          />
                        );
                      }
                    })}
                  </div>

                  {about.map((obj, i) => {
                    if (i == characterAbout) {
                      return (
                        <div className="wrapper__charter-detail">
                          <img src={obj.bg} className="img" alt="" />
                          <div className="desc ml-3 ml-sm-5">
                            <div className="user d-flex align-items-center mb-3">
                              <img src={obj.profile} alt="" />
                              <h5 className="ml-3 mb-0 semi-bold font__size--24">
                                {obj.name}
                              </h5>
                            </div>
                            <h4 className="semi-bold font__size--20">
                              Powers:
                            </h4>
                            <div className="d-flex flex-wrap tag">
                              {obj.power.map((k) => {
                                return (
                                  <div className="items normal font__size--12">
                                    {k}
                                  </div>
                                );
                              })}
                            </div>
                            <h4 className="semi-bold font__size--20">
                              <br></br>About:
                            </h4>
                            <p className="medium font__size--12 color__gray-1 mb-4">
                              {obj.desc}
                            </p>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          </section>
        </div>
        <img
          src="./../images/Subtract.png"
          className="w-100 img-cover"
          alt=""
        />

        <Footer />
      </div>
    </Fragment>
  );
};

export default Characters;
