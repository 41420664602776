import React, { Fragment, useEffect, useState } from "react";
import AccordionFaq from "../../component/Accordion/AccordionFaq";
import Footer from "../../component/Other/Footer";
import Head from "../../component/Page/Head";

const Faq = () => {
  const [toogle, setToogle] = useState(-1);

  const toogleFunc = (e) => {
    if (e == toogle) {
      setToogle(-1);
    } else {
      setToogle(e);
    }
  };

  const faqData = [
    {
      title: "How to play Realm Riches?",
      desc: "Discover the steps to immerse yourself in the Realm Riches universe. Learn how to begin your adventure, unlock rewards, and build your strategy to become the ultimate champion.  If you are new to Realm Riches, you are taken on an introductary journey after choosing your realm and your journey.  The objective is to build your empire in the levels, raid for riches and conquer the realms  ",
      category: "Installation & Technical Assistance",
    },
    {
      title: "The benefits of using Realm Riches?",
      desc: "Explore the various advantages of playing Realm Riches. From unique in-game assets to a thriving community, see why this game stands out among the rest.  Realm Riches is designed for minor unwind escape and is designed for fun and social activities with your friends.  It can even improve your mood and focus where you can pick it up and put it down at any time.",
      category: "Known Issues & Fixes",
    },
    {
      title: "How to download Realm Riches?",
      desc: "Find out how to download and install Realm Riches on your preferred platform. Get step-by-step guidance to start your journey smoothly.  Register your interest by subscribing on the home page and get access to our test beta program.  We plan to release the game on App store and Google Play Store soon!",
      category: "Installation & Technical Assistance",
    },
    {
      title: "Error Codes in Realm Riches?",
      desc: "Encounter an error? Learn about common error codes and how to troubleshoot and fix any issues you may face while playing Realm Riches.  As part of the beta testing program you can log an issue and submit it to the development team to make fixes and improvements to the game.  You may see an error code, when you generate a report and share it, you can add comments to describe what you were doing that caused the crash.",
      category: "Known Issues & Fixes",
    },
    {
      title: "Evolved Weapon Choices?",
      desc: "Learn how to access and apply evolved weapon skins to enhance your in-game experience and customize your character with these exclusive designs.  You can choose 1 of 7 realms and a choice of a male or female general.  Each realm and General have unique capabilities, when coupled they make a formidable gameplay strategy when facing opponents.",
      category: "Known Issues & Fixes",
    },
    {
      title: "Minimum Device to Play?",
      desc: "We have highlighted in the Tech Specs page the requirements.  Ensure the latest OS and security updates are applied to your device.  The minimum device to play realm riches is an iPhone 11, iPad 2017, Android 10 or above with min Snapdragon Quad Core CPU.",
      category: "Known Issues & Fixes",
    },
    {
      title: "How to Improve Realm Riches?",
      desc: "As part of the Beta Testing programme, you can offer suggestions and gameplay improvements, there is a review part of each gameplay part which allows you to comment on which part of the game needs improvements. ",
      category: "Known Issues & Fixes",
    },
    {
      title: "Is Realm Riches Free? ",
      desc: "Yes! You can play Realm Riches for free.  You may make in-app purchases to progress but it is not manditory to make payments to play.",
      category: "Known Issues & Fixes",
    },
    {
      title: "Which is the best Realm?",
      desc: "You must decide this at the beginning of the game.  Chose your Realm wisely as some realm opponents may have the advantages in different ways.  For example better builders, richer in resource, more cunning in battle, stronger and more fortified.",
      category: "Known Issues & Fixes",
    },
    {
      title: "Why can't I build a specific building?",
      desc: "You must gather the right resources and sometimes knowledge to be able to construct or convert a specfic building. The build resource list should tell you what you need to create the building of your choice.  Some building must be place in specic locations.",
      category: "Known Issues & Fixes",
    },
    {
      title: "Why can't I continue",
      desc: "We have designed Realm Riches to be a fun social game so you can pick up and put down at any time.  We don't want you to spend all your time playing as sometimes the game, your phone and you need a rest to recharge.  When you run out of resources there is a timer for when it becomes available again. ",
      category: "Known Issues & Fixes",
    },
  ];

  const [selcted, setSelcted] = useState("All");
  const [showing, setShowing] = useState([]);

  useEffect(() => {
    if (selcted != "All") {
      const data = faqData.filter((item) => item.category == selcted);
      setShowing(data);
    } else {
      setShowing(faqData);
    }
  }, [selcted]);

  return (
    <Fragment>
      <div className="overflow-hidden">
        <div className="bg__blue-2">
          <Head title={"FAQ"} />
        </div>
        <img
          src="./../images/Subtract.png"
          className="w-100 img-cover"
          alt=""
        />

        <section>
          <div className="container">
            <div className="wrapper__offside-faq">
              <div className="auto">
                <div className="wrapper__category-faq semi-bold font__size--16 text__16-1024 mb-5">
                  <div
                    className={
                      "items pointer " + (selcted == "All" ? "active" : "")
                    }
                    onClick={() => setSelcted("All")}
                  >
                    All
                  </div>
                  <div
                    className={
                      "items pointer " +
                      (selcted == "Installation & Technical Assistance"
                        ? "active"
                        : "")
                    }
                    onClick={() =>
                      setSelcted("Installation & Technical Assistance")
                    }
                  >
                    Installation & Technical Assistance
                  </div>
                  <div
                    className={
                      "items pointer " +
                      (selcted == "Known Issues & Fixes" ? "active" : "")
                    }
                    onClick={() => setSelcted("Known Issues & Fixes")}
                  >
                    Known Issues & Fixes
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                {showing.map((obj, i) => {
                  if (i < showing.length / 2) {
                    return (
                      <div className="mb-4">
                        <AccordionFaq
                          data={obj}
                          id={i}
                          toogle={toogle}
                          toogleFunc={(e) => toogleFunc(e)}
                        />
                      </div>
                    );
                  }
                })}
              </div>
              <div className="col-md-6">
                {showing.map((obj, i) => {
                  if (i >= showing.length / 2) {
                    return (
                      <div className="mb-4">
                        <AccordionFaq
                          data={obj}
                          id={i}
                          toogle={toogle}
                          toogleFunc={(e) => toogleFunc(e)}
                        />
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </Fragment>
  );
};

export default Faq;
