import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import CardBlog from "../../component/Card/CardBlog";
import Footer from "../../component/Other/Footer";
import Head from "../../component/Page/Head";

const News = () => {
  const blogFeatured = [
    {
      title: "Realm Riches will take social gameplay to the next level",
      sub: "Game Update",
      img: "./../images/fgh (2).png",
    },
    {
      title: "Why Realm Riches is the ultimate party game",
      sub: "Game Update",
      img: "./../images/fgh (1).png",
    },
  ];

  const blog = [
    {
      title: "Realm Riches will take social gameplay to the next level",
      sub: "Game Update",
      img: "./../images/fgh (2).png",
    },
    {
      title: "Why Realm Riches is the ultimate party game",
      sub: "Game Update",
      img: "./../images/fgh (1).png",
    },
    {
      title: "The top 5 Realm Riches games to beat",
      sub: "Game Update",
      img: "./../images/fgh (3).png",
    },
    {
      title: "Realm Riches will take social gameplay to the next level",
      sub: "Game Update",
      img: "./../images/gfh (1).png",
    },
    {
      title: "Why Realm Riches is the ultimate party game",
      sub: "Game Update",
      img: "./../images/gfh (2).png",
    },
    {
      title: "The top 5 Realm Riches games to beat",
      sub: "Game Update",
      img: "./../images/gfh (3).png",
    },
    {
      title: "How Realm Riches can take your gameplay to the next level",
      sub: "Game Update",
      img: "./../images/gfh (4).png",
    },
    {
      title: "Why Realm Riches is the ultimate party game",
      sub: "Game Update",
      img: "./../images/gfh (5).png",
    },
    {
      title: "The top 5 Realm Riches games to beat",
      sub: "Game Update",
      img: "./../images/gfh (6).png",
    },
  ];
  return (
    <Fragment>
      <div className="overflow-hidden">
        <div className="bg__blue-2">
          <Head title={"News"} />
        </div>
        <img
          src="./../images/Subtract.png"
          className="w-100 img-cover"
          alt=""
        />

        <section>
          <div className="container">
            <h2 className="semi-bold font__size--48 text__48-1024 text__48-md text__48-sm text__48-xx mb-3">
              Featured News
            </h2>
            <NavLink
              to="/news/detail"
              className="color__black d-inline-block w-100"
            >
              <img
                src="./../images/NewsArticle001.png"
                className="images__wrap-featured mb-3"
                alt=""
              />
              <p className="semi-bold font__size--18 text__18-1024 color__blue mb-2">
                Game Update | 25 Jan 2023
              </p>
              <h4 className="normal font__size--32 text__32-1024 text__32-xs text__32-xxs">
                Realm Riches will take social gameplay to the next level
              </h4>
            </NavLink>
          </div>
        </section>

        <section>
          <div className="container">
            <h2 className="semi-bold font__size--48 text__48-1024 text__48-md text__48-sm text__48-xx mb-3">
              Popular News
            </h2>

            <div className="row">
              <div className="col-lg-8 mb-4 mb-lg-0">
                <NavLink
                  to="/news/detail"
                  className="color__black d-inline-block w-100 wrapper__card-news"
                >
                  <img
                    src="./../images/asa (3).png"
                    className="img mb-3"
                    alt=""
                  />
                  <p className="semi-bold font__size--18 text__18-1024 color__blue mb-2">
                    Game Update | 25 Jan 2023
                  </p>
                  <h4 className="normal font__size--32 text__32-1024 text__32-xs text__32-xxs">
                    How Realm Riches can take your gameplay to the next level
                  </h4>
                </NavLink>
              </div>
              <div className="col-lg-4">
                <div className="d-lg-flex h-100 flex-wrap">
                  {blogFeatured.map((obj) => {
                    return (
                      <div className="mb-4">
                        <CardBlog data={obj} />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="mb-5">
              <h2 className="semi-bold font__size--48 text__48-1024 text__48-md text__48-sm text__48-xx mb-0">
                All News
              </h2>
            </div>

            <div className="row">
              {blog.map((obj) => {
                return (
                  <div className="col-md-6 col-lg-4 mb-4">
                    <CardBlog data={obj} />
                  </div>
                );
              })}
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </Fragment>
  );
};

export default News;
