import React from 'react'

const Head = (props) => {
    return (
        <section className="position-relative">
            <div className="container text-center">
                <h2 className='semi-bold font__size--60 text__65-1024 text__65-md text__65-sm'>{props.title}</h2>
            </div>
        </section>
    )
}

export default Head