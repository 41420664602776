import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import ForgotPassword from "../Modal/Auth/ForgotPassword";
import ModalSuccess from "../Modal/Auth/ModalSuccess";
import NewPassword from "../Modal/Auth/NewPassword";
import VerificationCode from "../Modal/Auth/VerificationCode";
import Code from "./../Modal/Auth/Code";
import Confirm from "./../Modal/Auth/Confirm";
import Login from "./../Modal/Auth/Login";
import Register from "./../Modal/Auth/Register";

const Navbar = ({ isNavOffTop }) => {
  const [toogleMenu, setToogleMenu] = useState(false);
  const [modalLogin, setModalLogin] = useState(false);

  const onCLickModalLogin = () => {
    if (!modalLogin) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
    setModalLogin(!modalLogin);
  };

  const [modalRegister, setModalRegister] = useState(false);
  const onCLickModalRegister = () => {
    if (!modalRegister) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
    setModalRegister(!modalRegister);
  };

  const [modalConfirm, setModalConfirm] = useState(false);
  const onCLickModalConfirm = () => {
    if (!modalConfirm) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
    setModalConfirm(!modalConfirm);
  };

  const [modalCode, setModalCode] = useState(false);
  const onCLickModalCode = () => {
    if (!modalCode) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
    setModalCode(!modalCode);
  };

  const [modalForgotPassword, setModalForgotPassword] = useState(false);
  const onCLickModalForgotPassword = () => {
    if (!modalForgotPassword) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
    setModalForgotPassword(!modalForgotPassword);
  };

  const [modalVerificationCode, setModalVerificationCode] = useState(false);
  const onCLickModalVerificationCode = () => {
    if (!modalVerificationCode) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
    setModalVerificationCode(!modalVerificationCode);
  };

  const [modalNewPassword, setModalNewPassword] = useState(false);
  const onCLickModalNewPassword = () => {
    if (!modalNewPassword) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
    setModalNewPassword(!modalNewPassword);
  };

  const [modalSuccess, setModalSuccess] = useState(false);
  const onCLickModalSuccess = () => {
    if (!ModalSuccess) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
    setModalSuccess(!modalSuccess);
  };

  const onCLickSwitchModal = async (e) => {
    if (e === "login") {
      setModalRegister(!modalRegister);
      const timeout = await setTimeout(() => {
        setModalLogin(!modalLogin);
      }, 1000);
      return () => clearInterval(timeout);
    }
    if (e === "register") {
      setModalLogin(!modalLogin);
      const timeout = await setTimeout(() => {
        setModalRegister(!modalRegister);
      }, 1000);
      return () => clearInterval(timeout);
    }
    if (e === "confirm") {
      if (modalLogin) {
        setModalLogin(!modalLogin);
      }
      if (modalRegister) {
        setModalRegister(!modalRegister);
      }
      const timeout = await setTimeout(() => {
        setModalConfirm(!modalConfirm);
      }, 1000);
      return () => clearInterval(timeout);
    }

    if (e === "code") {
      setModalConfirm(!modalConfirm);
      const timeout = await setTimeout(() => {
        setModalCode(!modalCode);
      }, 1000);
      return () => clearInterval(timeout);
    }

    if (e === "forgotPassowrd") {
      if (modalLogin) {
        setModalLogin(!modalLogin);
      }

      const timeout = await setTimeout(() => {
        setModalForgotPassword(!modalForgotPassword);
      }, 1000);
      return () => clearInterval(timeout);
    }

    if (e === "verificatonCode") {
      if (modalForgotPassword) {
        setModalForgotPassword(!modalForgotPassword);
      }

      const timeout = await setTimeout(() => {
        setModalVerificationCode(!modalVerificationCode);
      }, 1000);
      return () => clearInterval(timeout);
    }

    if (e === "newPassword") {
      if (modalVerificationCode) {
        setModalVerificationCode(!modalVerificationCode);
      }

      const timeout = await setTimeout(() => {
        setModalNewPassword(!modalNewPassword);
      }, 1000);
      return () => clearInterval(timeout);
    }

    if (e === "success") {
      if (modalNewPassword) {
        setModalNewPassword(!modalNewPassword);
      }

      const timeout = await setTimeout(() => {
        setModalSuccess(!modalSuccess);
      }, 1000);
      return () => clearInterval(timeout);
    }
  };

  return (
    <>
      <Login
        modalLogin={modalLogin}
        onCLickModalLogin={() => onCLickModalLogin()}
        onCLickSwitchModal={(e) => onCLickSwitchModal(e)}
      />
      <Register
        modalRegister={modalRegister}
        onCLickModalRegister={() => onCLickModalRegister()}
        onCLickSwitchModal={(e) => onCLickSwitchModal(e)}
      />
      <Confirm
        modalConfirm={modalConfirm}
        onCLickModalConfirm={() => onCLickModalConfirm()}
        onCLickSwitchModal={(e) => onCLickSwitchModal(e)}
      />
      <Code
        modalCode={modalCode}
        onCLickModalCode={() => onCLickModalCode()}
        onCLickSwitchModal={(e) => onCLickSwitchModal(e)}
      />

      <ForgotPassword
        modalForgotPassword={modalForgotPassword}
        onCLickModalForgotPassword={() => onCLickModalForgotPassword()}
        onCLickSwitchModal={(e) => onCLickSwitchModal(e)}
      />

      <VerificationCode
        modalVerificationCode={modalVerificationCode}
        onCLickModalVerificationCode={() => onCLickModalVerificationCode()}
        onCLickSwitchModal={(e) => onCLickSwitchModal(e)}
      />

      <NewPassword
        modalNewPassword={modalNewPassword}
        onCLickModalNewPassword={() => onCLickModalNewPassword()}
        onCLickSwitchModal={(e) => onCLickSwitchModal(e)}
      />

      <ModalSuccess
        modalSuccess={modalSuccess}
        onCLickModalSuccess={() => onCLickModalSuccess()}
        onCLickSwitchModal={(e) => onCLickSwitchModal(e)}
      />

      <div
        className={
          "wrapper__side-nav-mobile d-flex d-lg-none " +
          (toogleMenu ? "active" : "")
        }
      >
        <div className="d-flex flex-wrap w-100">
          <div className="w-100">
            <div className="d-flex align-items-center justify-content-between mb-4">
              <NavLink
                to="/"
                exact
                className="navbar-brand font__size--24 text__24-1024 semi-bold color__black"
              >
                <img src="./../images/Logo.svg" alt="" />
              </NavLink>
              <img
                src="./../images/clsoe2.png"
                onClick={() => setToogleMenu(!toogleMenu)}
                className="pointer"
                alt=""
              />
            </div>
            <div className="menu">
              <NavLink
                to="/about"
                className="medium font__size--14 text__14-1024 color__black"
              >
                About Us
              </NavLink>
              <hr />
              <NavLink
                to="/news"
                className="medium font__size--14 text__14-1024 color__black"
              >
                News
              </NavLink>
              <hr />
              <NavLink
                to="/leaderboard"
                className="medium font__size--14 text__14-1024 color__black"
              >
                Leaderboard
              </NavLink>
              <hr />
              <NavLink
                to="/characters"
                className="medium font__size--14 text__14-1024 color__black"
              >
                Characters
              </NavLink>
              <hr />
              <NavLink
                to="/maps"
                className="medium font__size--14 text__14-1024 color__black"
              >
                Maps
              </NavLink>
              <hr />
              <NavLink
                to="/faq"
                className="medium font__size--14 text__14-1024 color__black"
              >
                Support
              </NavLink>
              <hr />
              <div
                className="medium font__size--14 text__14-1024 btn btn__purple color__white shadow ml-auto text-capitalize rounded__12 px-4 pointer"
                onClick={() => onCLickModalLogin()}
              >
                Play Now
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="bg__wrap-menu d-lg-none"
        onClick={() => setToogleMenu(!toogleMenu)}
      ></div>
      <nav
        className={`navbar navbar-expand-lg bg__black-2 wrapper__navbar position-relative z-2 bg__blue-2 ${
          isNavOffTop && "open"
        }`}
        data-aos="fade-down"
        data-aos-duration="1000"
        data-aos-delay="300"
      >
        <div className="container position-relative">
          <NavLink
            to="/"
            exact
            className="navbar-brand font__size--24 text__24-1024 semi-bold color__black"
          >
            <img src="./../images/Logo.svg" alt="Realm Riches" />
          </NavLink>

          <div
            className="collapse navbar-collapse wrapper__navbar-menu ml-lg-5"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav menu__center">
              <li className="nav-item">
                <NavLink
                  to="/about"
                  className="nav-link medium font__size--14 text__14-1024 color__black opacity__7 text-capitalize hover"
                >
                  About Us
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/news"
                  className="nav-link medium font__size--14 text__14-1024 color__black opacity__7 text-capitalize hover"
                >
                  News
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/leaderboard"
                  className="nav-link medium font__size--14 text__14-1024 color__black opacity__7 text-capitalize hover"
                >
                  Leaderboard
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/characters"
                  className="nav-link medium font__size--14 text__14-1024 color__black opacity__7 text-capitalize hover"
                >
                  Characters
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/maps"
                  className="nav-link medium font__size--14 text__14-1024 color__black opacity__7 text-capitalize hover"
                >
                  Maps
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/faq"
                  className="nav-link medium font__size--14 text__14-1024 color__black opacity__7 text-capitalize hover"
                >
                  Support
                </NavLink>
              </li>
            </ul>
          </div>

          <div
            onClick={() => setToogleMenu(!toogleMenu)}
            className="nav__button d-lg-none position-relative ml-auto flex-shrink-0"
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div
            className="d-none d-lg-block medium font__size--14 text__14-1024 btn btn__purple color__white shadow ml-auto text-capitalize rounded__12 px-4 pointer"
            onClick={() => onCLickModalLogin()}
          >
            Play Now
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
