import React, { useEffect, useState } from "react";
import { HashRouter, Outlet, Route, Routes } from "react-router-dom";

import Navbar from "../component/Other/Navbar";
import Login from "./Auth/Login";
import About from "./homepage/About";
import Characters from "./homepage/Characters";
import DetailMaps from "./homepage/DetailMaps";
import DetailNews from "./homepage/DetailNews";
import Faq from "./homepage/Faq";
import Homepage from "./homepage/index";
import Leaderboard from "./homepage/Leaderboard";
import Maps from "./homepage/Maps";
import News from "./homepage/News";
import PlayerDetail from "./homepage/PlayerDetail";
import Privacy from "./homepage/Privacy";
import Spec from "./homepage/Spec";
import Terms from "./homepage/Terms";

const Layout = () => {
  const [navOffTop, setNavOffTop] = useState(false);

  const isScrolledOffTop = () => {
    window.scrollY > 0 ? setNavOffTop(true) : setNavOffTop(false);
  };

  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", isScrolledOffTop);
    }
    watchScroll();
    return () => {
      window.removeEventListener("scroll", isScrolledOffTop);
    };
  }, []);

  return (
    <>
      <Navbar isNavOffTop={navOffTop} />
      <Outlet />
    </>
  );
};

const Index = (props) => {
  return (
    <HashRouter>
      <Routes>
        <Route exact path="/" element={<Layout />}>
          <Route index element={<Homepage />} />
          <Route path="characters" element={<Characters />} />
          <Route path="leaderboard" element={<Leaderboard />} />
          <Route path="player/detail" element={<PlayerDetail />} />
          <Route path="news" element={<News />} />
          <Route path="news/detail" element={<DetailNews />} />
          <Route path="about" element={<About />} />
          <Route path="maps" element={<Maps />} />
          <Route path="maps/detail" element={<DetailMaps />} />
          <Route path="spec" element={<Spec />} />
          <Route path="faq" element={<Faq />} />
          <Route path="terms" element={<Terms />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="login" element={<Login />} />
        </Route>
      </Routes>
    </HashRouter>
  );
};

export default Index;
