import React from 'react'
import { useRef } from 'react';
import Slider from "react-slick";

const SliderTestimoial = () => {
    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        centerMode: true,
        infinite: true,
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 580,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                    variableWidth: false,
                }
            },
        ]
    };

    const slider1 = useRef(null);

    const previous = () => {
        slider1.current.slickNext();
    };

    const next = () => {
        slider1.current.slickPrev();
    };
    return (

        <Slider ref={slider1} {...settings} className='wrapper__slider-tetsimonials'>
            <div className="items">
                <div className="wrapper__card-user text-center">
                    <div className="wrapper__star justify-content-center mb-4">
                        <img src="./../images/Stars.png" alt="" />
                        <img src="./../images/Stars.png" alt="" />
                        <img src="./../images/Stars.png" alt="" />
                        <img src="./../images/Stars.png" alt="" />
                        <img src="./../images/Stars.png" alt="" />
                    </div>
                    <h3 className='semi-bold font__size--24 text__24-1024 mb-3'>I can't wait for this to be released!</h3>
                    <p className='medium font__size--12 text__12-1024 color__gray-1 lh-2 mb-4'>I have been playing the concept for Realm Riches for a few weeks now and I am honestly excited, I can't wait for this to be released so I can raid against my friends.  Thank you to Desert Diamond Games for letting me QA this game, its an amazing experience! </p>

                    <div className="user">
                        <img src="./../images/lalala12.png" className='mb-3' alt="" />
                        <h5 className='semi-bold font__size--24 text__24-1024 mb-0'>lalala12</h5>
                    </div>
                </div>
            </div>
            <div className="items">
                <div className="wrapper__card-user text-center">
                    <div className="wrapper__star justify-content-center mb-4">
                        <img src="./../images/Stars.png" alt="" />
                        <img src="./../images/Stars.png" alt="" />
                        <img src="./../images/Stars.png" alt="" />
                        <img src="./../images/Stars.png" alt="" />
                        <img src="./../images/Stars.png" alt="" />
                    </div>
                    <h3 className='semi-bold font__size--24 text__24-1024 mb-3'>OMG, this looks SOOO GOOD!</h3>
                    <p className='medium font__size--12 text__12-1024 color__gray-1 lh-2 mb-4'>I love the characters and the in game animations and effects, it's really immersive and you can play as little or as much as you like.  The in game level maps and mini quests are soo much fun, and the detail, graphics and effects... OMG, this looks SOOO GOOD! I can't wait for the game to be released!</p>

                    <div className="user">
                        <img src="./../images/greekmush22.png" className='mb-3' alt="" />
                        <h5 className='semi-bold font__size--24 text__24-1024 mb-0'>greekmush22</h5>
                    </div>
                </div>
            </div>
            <div className="items">
                <div className="wrapper__card-user text-center">
                    <div className="wrapper__star justify-content-center mb-4">
                        <img src="./../images/Stars.png" alt="" />
                        <img src="./../images/Stars.png" alt="" />
                        <img src="./../images/Stars.png" alt="" />
                        <img src="./../images/Stars.png" alt="" />
                        <img src="./../images/Stars.png" alt="" />
                    </div>
                    <h3 className='semi-bold font__size--24 text__24-1024 mb-3'>You can customise your character!</h3>
                    <p className='medium font__size--12 text__12-1024 color__gray-1 lh-2 mb-4'>One thing I love about this game, is when you setup your player avatar and profile, you can make a mini general type player profile  I chose an Arab look which is awesome, it matches my chosen general Hamza, I love it! </p>

                    <div className="user">
                        <img src="./../images/robertcow.png" className='mb-3' alt="" />
                        <h5 className='semi-bold font__size--24 text__24-1024 mb-0'>robertcow</h5>
                    </div>
                </div>
            </div>
            <div className="items">
                <div className="wrapper__card-user text-center">
                    <div className="wrapper__star justify-content-center mb-4">
                        <img src="./../images/Stars.png" alt="" />
                        <img src="./../images/Stars.png" alt="" />
                        <img src="./../images/Stars.png" alt="" />
                        <img src="./../images/Stars.png" alt="" />
                        <img src="./../images/Stars.png" alt="" />
                    </div>
                    <h3 className='semi-bold font__size--24 text__24-1024 mb-3'>Simply Hillarious!</h3>
                    <p className='medium font__size--12 text__12-1024 color__gray-1 lh-2 mb-4'>This game really makes you laugh, but in a good way!  The appearance of the characters and special raid attacks and furious and simply hillarous, I keep laughing when I raid coins from the enemy - cha-ching! </p>

                    <div className="user">
                        <img src="./../images/baldbob82.png" className='mb-3' alt="" />
                        <h5 className='semi-bold font__size--24 text__24-1024 mb-0'>baldbob82</h5>
                    </div>
                </div>
            </div>
            <div className="items">
                <div className="wrapper__card-user text-center">
                    <div className="wrapper__star justify-content-center mb-4">
                        <img src="./../images/Stars.png" alt="" />
                        <img src="./../images/Stars.png" alt="" />
                        <img src="./../images/Stars.png" alt="" />
                        <img src="./../images/Stars.png" alt="" />
                        <img src="./../images/Stars.png" alt="" />
                    </div>
                    <h3 className='semi-bold font__size--24 text__24-1024 mb-3'>Best Game Ever!!!</h3>
                    <p className='medium font__size--12 text__12-1024 color__gray-1 lh-2 mb-4'>I absolutely love the fact that you can choose a realm.  I am from Asia, so naturally I wanted to pick an Asian realm and choose an Asian General.  It's so much fun to play... This quite honestly be the best Game ever!!!  </p>

                    <div className="user">
                        <img src="./../images/pudlove77.png" className='mb-3' alt="" />
                        <h5 className='semi-bold font__size--24 text__24-1024 mb-0'>pudlove77</h5>
                    </div>
                </div>
            </div>
            <div className="items">
                <div className="wrapper__card-user text-center">
                    <div className="wrapper__star justify-content-center mb-4">
                        <img src="./../images/Stars.png" alt="" />
                        <img src="./../images/Stars.png" alt="" />
                        <img src="./../images/Stars.png" alt="" />
                        <img src="./../images/Stars.png" alt="" />
                        <img src="./../images/Stars.png" alt="" />
                    </div>
                    <h3 className='semi-bold font__size--24 text__24-1024 mb-3'>This shows a lot of promise!</h3>
                    <p className='medium font__size--12 text__12-1024 color__gray-1 lh-2 mb-4'>I have played a lot of social games in the past, you end up grinding a lot or sinking a lot of money into these things, but I love the fact you can build villages, watch them grow.  For me the Egypt realm was a no brainer choice.  I love the animations, this shows a lot of promise.  </p>

                    <div className="user">
                        <img src="./../images/kingtut.png" className='mb-3' alt="" />
                        <h5 className='semi-bold font__size--24 text__24-1024 mb-0'>kingtut</h5>
                    </div>
                </div>
            </div>
        </Slider>
    )
}

export default SliderTestimoial