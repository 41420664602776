import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import Footer from "../../component/Other/Footer";
import Head from "../../component/Page/Head";
import SecBanner from "../../component/Page/SecBanner";

const Maps = () => {
  return (
    <Fragment>
      <div className="overflow-hidden">
        <div className="bg__blue-2">
          <Head title={"Maps"} />
        </div>
        <img
          src="./../images/Subtract.png"
          className="w-100 img-cover"
          alt=""
        />

        <section>
          <div className="container">
            {/* Start block Image left, content right */}
            <div className="row mb-5">
              <div className="col-md-6 mb-4 text-center my-md-auto">
                <img
                  src="./../images/HeroGreekRealm.png"
                  className="images__maps left"
                  alt=""
                />
              </div>
              <div className="col-md-6 my-auto">
                <h2 className="semi-bold font__size--54 text__50-1024 text__50-sm mb-4">
                  Greek Realm
                </h2>
                <p className="medium font__size--12 text__12-1024 color__gray-1 lh-2 mb-4">
                  Step into the mystical realm of ancient Greece, where gods and
                  legends once roamed. In this enchanting world, your journey
                  spans across a breathtaking landscape filled with divine
                  wonders and mythical creatures. From the endless skies above
                  to the rugged mountains and serene islands scattered through
                  the seas, you’ll experience the magic and grandeur of a land
                  blessed by the gods covering the seas, islands, mountains and
                  lush planes.
                </p>
                <NavLink
                  to="/maps/detail"
                  className="d-inline-block semi-bold font__size--16 text__16-1024 btn btn__purple px-4 color__white shadow ml-auto text-capitalize rounded__12"
                >
                  Detail Location
                </NavLink>
              </div>
            </div>
            {/* End block Image left, content right */}
            {/* Start block Image right, content left */}
            <div className="row mb-5">
              <div className="col-md-6 order-md-1 order-12 my-auto">
                <h2 className="semi-bold font__size--54 text__50-1024 text__50-sm mb-4">
                  Norse Realm
                </h2>
                <p className="medium font__size--12 text__12-1024 color__gray-1 lh-2 mb-4">
                  Enter the rugged and untamed land of the Norse, where the
                  ancient gods of Asgard reign and fierce warriors tread. This
                  is a realm shaped by the raw forces of nature, a land of icy
                  tundras, towering mountains, vast fjords, and endless
                  flatlands. With each step, you’ll uncover the heart of Norse
                  mythology, with echoes of Odin, Thor, and Loki resonating
                  through the landscape. The elements are harsh, but the rewards
                  for bravery are legendary.
                </p>
                <NavLink
                  to="/maps/detail"
                  className="d-inline-block semi-bold font__size--16 text__16-1024 btn btn__purple px-4 color__white shadow ml-auto text-capitalize rounded__12"
                >
                  Detail Location
                </NavLink>
              </div>
              <div className="col-md-6 order-1 order-md-12 mb-4 text-center my-md-auto">
                <img
                  src="./../images/HeroNorseRealm.png"
                  className="images__maps right"
                  alt=""
                />
              </div>
            </div>
            {/* End block Image right, content left */}

            <div className="row">
              <div className="col-md-6 mb-4 text-center my-md-auto">
                <img
                  src="./../images/HeroTechnoRealm.png"
                  className="images__maps left"
                  alt=""
                />
              </div>
              <div className="col-md-6 my-auto">
                <h2 className="semi-bold font__size--54 text__50-1024 text__50-sm mb-4">
                  Techno Realm
                </h2>
                <p className="medium font__size--12 text__12-1024 color__gray-1 lh-2 mb-4">
                  Welcome to the Techno Realm, a futuristic world where towering
                  skyscrapers, endless wastelands, and high-tech marvels
                  dominate the landscape. In this cyberpunk-inspired
                  environment, humanity and technology intertwine in a
                  relentless push for progress. Neon lights, shadowy alleys, and
                  sprawling industrial zones define a world on the brink of
                  innovation or collapse. It’s a place where the bold thrive and
                  only the strongest survive the harsh reality of this high-tech
                  existence.
                </p>
                <NavLink
                  to="/maps/detail"
                  className="d-inline-block semi-bold font__size--16 text__16-1024 btn btn__purple px-4 color__white shadow ml-auto text-capitalize rounded__12"
                >
                  Detail Location
                </NavLink>
              </div>
            </div>
          </div>
        </section>

        <SecBanner />
        <section>
          <div className="container">
            {/* Start block Image left, content right */}
            <div className="row mb-5">
              <div className="col-md-6 mb-4 text-center my-md-auto">
                <img
                  src="./../images/HeroTikiRealm.png"
                  className="images__maps left"
                  alt=""
                />
              </div>
              <div className="col-md-6 my-auto">
                <h2 className="semi-bold font__size--54 text__50-1024 text__50-sm mb-4">
                  Tiki Realm
                </h2>
                <p className="medium font__size--12 text__12-1024 color__gray-1 lh-2 mb-4">
                  Step into the vibrant and mysterious Tiki Realm, where the
                  warmth of the sun meets the untamed power of nature. This
                  tropical paradise is filled with rich traditions, ancient
                  deities, and hidden treasures. From serene sandy beaches to
                  the fiery volcanic wastelands, through dense tropical forests
                  and long-lost cities, the Tiki Realm offers a journey through
                  the heart of nature’s primal forces and the remnants of
                  forgotten civilisations. Adventure awaits beneath the lush
                  canopies and beneath the waves.
                </p>
                <NavLink
                  to="/maps/detail"
                  className="d-inline-block semi-bold font__size--16 text__16-1024 btn btn__purple px-4 color__white shadow ml-auto text-capitalize rounded__12"
                >
                  Detail Location
                </NavLink>
              </div>
            </div>
            {/* End block Image left, content right */}
            {/* Start block Image right, content left */}
            <div className="row mb-5">
              <div className="col-md-6 order-md-1 order-12 my-auto">
                <h2 className="semi-bold font__size--54 text__50-1024 text__50-sm mb-4">
                  Arab Realm
                </h2>
                <p className="medium font__size--12 text__12-1024 color__gray-1 lh-2 mb-4">
                  Venture into the mesmerizing Arab Realm, a land where the past
                  and present coexist in breathtaking harmony. From the vast,
                  unrelenting deserts to bustling modern cities, this realm is a
                  journey through time, where ancient fortresses guard timeless
                  secrets, and shimmering oases offer respite amid rolling
                  dunes. It’s a world of contrast—where harsh, arid landscapes
                  are balanced by thriving cities with towering skyscrapers and
                  bustling marketplaces. This is a realm of mystery, beauty, and
                  unyielding spirit.
                </p>
                <NavLink
                  to="/maps/detail"
                  className="d-inline-block semi-bold font__size--16 text__16-1024 btn btn__purple px-4 color__white shadow ml-auto text-capitalize rounded__12"
                >
                  Detail Location
                </NavLink>
              </div>
              <div className="col-md-6 order-1 order-md-12 mb-4 text-center my-md-auto">
                <img
                  src="./../images/HeroArabRealm.png"
                  className="images__maps right"
                  alt=""
                />
              </div>
            </div>
            {/* End block Image right, content left */}

            <div className="row">
              <div className="col-md-6 mb-4 text-center my-md-auto">
                <img
                  src="./../images/HeroAsianRealm.png"
                  className="images__maps left"
                  alt=""
                />
              </div>
              <div className="col-md-6 my-auto">
                <h2 className="semi-bold font__size--54 text__50-1024 text__50-sm mb-4">
                  Asian Realm
                </h2>
                <p className="medium font__size--12 text__12-1024 color__gray-1 lh-2 mb-4">
                  Step into the enchanting Asian Realm, a land of serene beauty,
                  spiritual depth, and ancient tradition. This is a world where
                  towering mountains give way to hidden temples, where secluded
                  villages thrive amid lush landscapes, and where rivers wind
                  through peaceful plains dotted with cherry blossoms. From the
                  quiet majesty of ancient sanctuaries to the simple charm of
                  life in small, idyllic islands, this realm offers a journey
                  through the natural and cultural wonders of the East. Tranquil
                  yet full of mystery, the Asian Realm is a place where nature
                  and spirit come together in perfect harmony.
                </p>
                <NavLink
                  to="/maps/detail"
                  className="d-inline-block semi-bold font__size--16 text__16-1024 btn btn__purple px-4 color__white shadow ml-auto text-capitalize rounded__12"
                >
                  Detail Location
                </NavLink>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-md-6 order-md-1 order-12 my-auto">
                <h2 className="semi-bold font__size--54 text__50-1024 text__50-sm mb-4">
                  Egypt Realm
                </h2>
                <p className="medium font__size--12 text__12-1024 color__gray-1 lh-2 mb-4">
                  Enter the legendary Egyptian Realm, a land steeped in the
                  grandeur of ancient civilizations and mystical wonders. Here,
                  the vast deserts stretch endlessly beneath the scorching sun,
                  dotted with awe-inspiring monuments that have stood the test
                  of time. Mighty rivers flow through the realm, nourishing the
                  land and hiding the secrets of lost cities beneath their
                  depths. Among the sand dunes and riverbanks lie ruins of
                  once-glorious cities, some now abandoned, others made entirely
                  of gold. In this realm, the echoes of pharaohs, gods, and
                  forgotten empires call out to those brave enough to uncover
                  their mysteries.
                </p>
                <NavLink
                  to="/maps/detail"
                  className="d-inline-block semi-bold font__size--16 text__16-1024 btn btn__purple px-4 color__white shadow ml-auto text-capitalize rounded__12"
                >
                  Detail Location
                </NavLink>
              </div>
              <div className="col-md-6 order-1 order-md-12 mb-4 text-center my-md-auto">
                <img
                  src="./../images/HeroEgyptRealm.png"
                  className="images__maps right"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </Fragment>
  );
};

export default Maps;
