import React, { Fragment } from "react";
import CardCharacter from "../../component/Card/CardCharacter";
import Footer from "../../component/Other/Footer";
import Head from "../../component/Page/Head";

const PlayerDetail = () => {
  const character = [
    "./../images/DannydAllyHero001.png",
    "./../images/DannydAllyHero002.png",
    "./../images/DannydAllyHero003.png",
    "./../images/DannydAllyHero004.png",
  ];
  return (
    <Fragment>
      <div className="overflow-hidden">
        <div className="bg__blue-2">
          <Head title={"Detail Player"} />
        </div>
        <img
          src="./../images/Subtract.png"
          className="w-100 img-cover"
          alt=""
        />

        <section className="pb-0">
          <div className="container">
            <div className="row">
              <div className="col-md-6 my-auto">
                <img src="./../images/DannyDProfile.png" alt="" />
              </div>
              <div className="col-md-6 my-auto">
                <div className="d-flex align-items-center wrapper__profile-player mb-4">
                  <div className="d-flex align-items-center">
                    <div className="position-relative user flex-shrink-0">
                      <img src="./../images/Crown.png" className="win" alt="" />
                      <img
                        src="./../images/DaringDannyX.png"
                        className="img"
                        alt=""
                      />
                    </div>
                    <h4 className="mb-0 semi-bold font__size--24 text__24-1024 ml-2">
                      DaringdannyX (General Louka)
                    </h4>
                  </div>
                  <a
                    href="#!"
                    className="d-inline-block semi-bold font__size--16 text__16-1024 btn btn__purple px-4 color__white shadow text-capitalize rounded__12 ml-4"
                  >
                    Follow
                  </a>
                </div>

                <h4 className="semi-bold font__size--20 text__20-1024">
                  About me
                </h4>
                <p className="medium font__size--12 text__12-1024 color__gray-1 lh-2 mb-4">
                  I enjoy playing social games when I have spare time either
                  commuting on transport or something to help kill time during
                  lunch breaks. I enjoy keeping fit, going to the gym, dune
                  bashing in the desert in 4x4 Jeeps and I love my pet dog.
                  Daringdannyx has been a member of the testgroup for 3 months.{" "}
                </p>

                <div className="row normal font__size--20 text__20-1024 mb-1 mb-xl-3">
                  <div className="col-2">Rank</div>
                  <div className="col-5">Stars</div>
                  <div className="col-2">Points</div>
                </div>
                <div className="row normal">
                  <div className="my-auto col-2 semi-bold font__size--30 text__30-1024  text__30-xx">
                    01
                  </div>
                  <div className="my-auto col-5">
                    <div className="wrapper__star small">
                      <img src="./../images/Stars.png" alt="" />
                      <img src="./../images/Stars.png" alt="" />
                      <img src="./../images/Stars.png" alt="" />
                      <img src="./../images/Stars.png" alt="" />
                      <img src="./../images/Stars.png" alt="" />
                    </div>
                  </div>
                  <div className="my-auto col-2">
                    <div className="d-flex align-items-center semi-bold font__size--24 text__24-1024">
                      <img src="./../images/dollar (1) 1.png" alt="" />
                      <span className="ml-2">83342</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <h2 className="semi-bold font__size--48 text__48-1024 text__48-md text__48-sm text__48-xx mb-5">
              Player Alliances
            </h2>

            <div className="row">
              {character.map((obj) => {
                return (
                  <div className="col-md-3">
                    <CardCharacter data={obj} />
                  </div>
                );
              })}
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <h2 className="semi-bold font__size--48 text__48-1024 text__48-md text__48-sm text__48-xx mb-5">
              Player’s Video
            </h2>
            <div className="row">
              <div className="col-md-6 mb-4 mb-md-0">
                <div className="wrapper__video-play position-relative">
                  <img
                    src="./../images/6010f77ad777b59edaf5b7e44b96b503.jfif"
                    className="bg"
                    alt=""
                  />
                  <div className="cover"></div>
                  <img
                    src="./../images/Play.png"
                    className="play pointer"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="wrapper__video-play position-relative">
                  <img
                    src="./../images/ee620f9f56bed41b20c8453d36a91d48.jfif"
                    className="bg"
                    alt=""
                  />
                  <div className="cover"></div>
                  <img
                    src="./../images/Play.png"
                    className="play pointer"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </Fragment>
  );
};

export default PlayerDetail;
