import React, { Fragment } from "react";
import Footer from "../../component/Other/Footer";
import Head from "../../component/Page/Head";

const Privacy = () => {
  return (
    <Fragment>
      <div className="overflow-hidden">
        <div className="bg__blue-2">
          <Head title={"Privacy Policy"} />
        </div>
        <img
          src="./../images/Subtract.png"
          className="w-100 img-cover"
          alt=""
        />

        <section>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-10">
                <div className="wrapper__text-wrap">
                  <p className="  normal font__size--18 text__18-1024 color__gray-1 lh-2 mb-5">
                    <h4>Last Updated: 21/08/2024</h4> <br /> At Realm Riches,
                    your privacy is important to us. This Privacy Policy
                    outlines the information we collect, how we use it, and the
                    choices you have in relation to your data.
                  </p>
                  <div className="mb-5">
                    <h2 className="semi-bold font__size--48 text__48-1024 text__48-md text__48-sm">
                      Licensing Policy
                    </h2>
                  </div>
                  <div className="mb-5">
                    <p className="  normal font__size--18 text__18-1024 color__gray-1 lh-2">
                      1. Information We Collect
                    </p>
                    <h4 className="semi-bold font__size--20 text__20-1024 mb-3">
                      When you play Realm Riches, we may collect the following
                      types of information:
                    </h4>

                    <ul className="list__normal  normal font__size--18 text__18-1024 color__gray-1">
                      <li>
                        <div className="d-flex align-items-start">
                          <img
                            src="./../images/Frame.png"
                            className="object-fit-content mt-1"
                            alt=""
                          />
                          <div className="ml-2">
                            Personal Information: When you create an account, we
                            may collect your name, email address, and payment
                            information (for in-game purchases).
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-start">
                          <img
                            src="./../images/Frame.png"
                            className="object-fit-content mt-1"
                            alt=""
                          />
                          <div className="ml-2">
                            Usage Information: We collect data on how you use
                            the game, including gameplay statistics,
                            achievements, and in-game purchases.
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-start">
                          <img
                            src="./../images/Frame.png"
                            className="object-fit-content mt-1"
                            alt=""
                          />
                          <div className="ml-2">
                            Device Information: We gather details about the
                            device you're using to access the game, including
                            device type, operating system, IP address, and
                            browser type.
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div className="mb-5">
                    <p className="  normal font__size--18 text__18-1024 color__gray-1 lh-2">
                      2. How we use your information:
                    </p>
                    <h4 className="semi-bold font__size--20 text__20-1024 mb-3">
                      The data collected is used to:
                    </h4>

                    <ul className="list__normal  normal font__size--18 text__18-1024 color__gray-1">
                      <li>
                        <div className="d-flex align-items-start">
                          <img
                            src="./../images/Frame.png"
                            className="object-fit-content mt-1"
                            alt=""
                          />
                          <div className="ml-2">
                            Provide and enhance your gameplay experience.
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-start">
                          <img
                            src="./../images/Frame.png"
                            className="object-fit-content mt-1"
                            alt=""
                          />
                          <div className="ml-2">
                            Process transactions for in-game purchases.
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-start">
                          <img
                            src="./../images/Frame.png"
                            className="object-fit-content mt-1"
                            alt=""
                          />
                          <div className="ml-2">
                            Communicate with you, including sending updates,
                            promotions, and event notifications.
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-start">
                          <img
                            src="./../images/Frame.png"
                            className="object-fit-content mt-1"
                            alt=""
                          />
                          <div className="ml-2">
                            Monitor and improve the game’s performance.
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-start">
                          <img
                            src="./../images/Frame.png"
                            className="object-fit-content mt-1"
                            alt=""
                          />
                          <div className="ml-2">
                            Ensure compliance with our Terms of Service and
                            Licensing Agreements.
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="mb-5">
                    <p className="  normal font__size--18 text__18-1024 color__gray-1 lh-2">
                      3. Sharing Your Information
                    </p>
                    <h4 className="semi-bold font__size--20 text__20-1024 mb-3">
                      We may share your data in the following circumstances:
                    </h4>

                    <ul className="list__normal  normal font__size--18 text__18-1024 color__gray-1">
                      <li>
                        <div className="d-flex align-items-start">
                          <img
                            src="./../images/Frame.png"
                            className="object-fit-content mt-1"
                            alt=""
                          />
                          <div className="ml-2">
                            With third-party service providers to process
                            payments, storage data, or provide anomolysed
                            analytics.
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-start">
                          <img
                            src="./../images/Frame.png"
                            className="object-fit-content mt-1"
                            alt=""
                          />
                          <div className="ml-2">
                            In response to legal obligations, such as complying
                            with court orders.
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-start">
                          <img
                            src="./../images/Frame.png"
                            className="object-fit-content mt-1"
                            alt=""
                          />
                          <div className="ml-2">
                            To protect the rights and safety of Realm Riches,
                            its users, or others.
                          </div>
                        </div>
                      </li>
                      <p className="  normal font__size--18 text__18-1024 color__gray-1 lh-2">
                        We do not sell your personal data to third parties for
                        marketing purposes.
                      </p>
                    </ul>
                  </div>
                  <div className="mb-5">
                    <p className="  normal font__size--18 text__18-1024 color__gray-1 lh-2">
                      4. Your Choices and Rights:
                    </p>
                    <h4 className="semi-bold font__size--20 text__20-1024 mb-3">
                      You have the right to:
                    </h4>

                    <ul className="list__normal  normal font__size--18 text__18-1024 color__gray-1">
                      <li>
                        <div className="d-flex align-items-start">
                          <img
                            src="./../images/Frame.png"
                            className="object-fit-content mt-1"
                            alt=""
                          />
                          <div className="ml-2">
                            Access and update your personal information within
                            the game.
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-start">
                          <img
                            src="./../images/Frame.png"
                            className="object-fit-content mt-1"
                            alt=""
                          />
                          <div className="ml-2">
                            Request that we delete your personal information
                            (note that this may result in losing access to your
                            game account).
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-start">
                          <img
                            src="./../images/Frame.png"
                            className="object-fit-content mt-1"
                            alt=""
                          />
                          <div className="ml-2">
                            Opt-out of promotional communications at any time.
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="mb-5">
                    <p className="  normal font__size--18 text__18-1024 color__gray-1 lh-2">
                      5. Data Security
                    </p>
                    <h4 className="semi-bold font__size--20 text__20-1024 mb-3">
                      Our Security Policy:
                    </h4>
                    <p className="  normal font__size--18 text__18-1024 color__gray-1 lh-2">
                      We implement industry-standard security measures to
                      protect your data. However, no system is completely
                      secure, and we cannot guarantee the absolute security of
                      your information.
                    </p>
                  </div>
                  <div className="mb-5">
                    <p className="  normal font__size--18 text__18-1024 color__gray-1 lh-2">
                      6. Childrens privacy
                    </p>
                    <h4 className="semi-bold font__size--20 text__20-1024 mb-3">
                      Game is Rated 13+:
                    </h4>
                    <p className="  normal font__size--18 text__18-1024 color__gray-1 lh-2">
                      Realm Riches is not intended for children under 13 years
                      of age. We do not knowingly collect personal information
                      from children under 13.
                    </p>
                  </div>
                  <div className="mb-5">
                    <p className="  normal font__size--18 text__18-1024 color__gray-1 lh-2">
                      7. Changes to this Privacy Policy
                    </p>
                    <h4 className="semi-bold font__size--20 text__20-1024 mb-3">
                      Updating our policies:
                    </h4>
                    <p className="  normal font__size--18 text__18-1024 color__gray-1 lh-2">
                      We may update this Privacy Policy from time to time. When
                      changes are made, we will notify you through the game or
                      via email.
                    </p>
                  </div>
                  <div className="mb-5">
                    <p className="  normal font__size--18 text__18-1024 color__gray-1 lh-2">
                      8. Contact Us
                    </p>
                    <h4 className="semi-bold font__size--20 text__20-1024 mb-3">
                      Game is Rated 13+:
                    </h4>
                    <p className="  normal font__size--18 text__18-1024 color__gray-1 lh-2">
                      If you have any questions about this Privacy Policy,
                      please contact us at{" "}
                      <a href="mailto:support@realmriches.com" target="_blank">
                        email support
                      </a>
                    </p>
                  </div>

                  <div className="mb-5">
                    <h2 className="semi-bold font__size--48 text__48-1024 text__48-md text__48-sm">
                      End User Licensing Agreement (EULA)
                    </h2>
                    <p className="  normal font__size--18 text__18-1024 color__gray-1 lh-2">
                      This Licensing Agreement outlines the terms and conditions
                      for using Realm Riches. By installing, accessing, or using
                      the game, you agree to comply with this agreement.
                    </p>
                  </div>

                  <div className="mb-5">
                    <h4 className="semi-bold font__size--20 text__20-1024 mb-3">
                      1. License Grant
                    </h4>
                    <p className="  normal font__size--18 text__18-1024 color__gray-1 lh-2">
                      We grant you a non-exclusive, non-transferable, revocable
                      license to use Realm Riches for personal entertainment
                      purposes, subject to your compliance with this Agreement.
                    </p>
                  </div>
                  <div className="mb-5">
                    <h4 className="semi-bold font__size--20 text__20-1024 mb-3">
                      2. Ownership
                    </h4>
                    <p className="  normal font__size--18 text__18-1024 color__gray-1 lh-2">
                      All content, including but not limited to graphics,
                      gameplay mechanics, in-game items, and character designs,
                      remains the property of Realm Riches and its licensors.
                      You are granted no rights to ownership of any in-game
                      assets, whether earned or purchased.
                    </p>
                  </div>
                  <div className="mb-5">
                    <h4 className="semi-bold font__size--20 text__20-1024 mb-3">
                      3. Prohibited Conduct
                    </h4>
                    <p className="  normal font__size--18 text__18-1024 color__gray-1 lh-2">
                      You agree not to:
                    </p>
                    <ul className="list__normal  normal font__size--18 text__18-1024 color__gray-1">
                      <li>
                        <div className="d-flex align-items-start">
                          <img
                            src="./../images/Frame.png"
                            className="object-fit-content mt-1"
                            alt=""
                          />
                          <div className="ml-2">
                            Reverse-engineer, decompile, or disassemble the
                            game.
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-start">
                          <img
                            src="./../images/Frame.png"
                            className="object-fit-content mt-1"
                            alt=""
                          />
                          <div className="ml-2">
                            Use cheats, bots, or any unauthorized third-party
                            software that alters the gameplay experience.
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-start">
                          <img
                            src="./../images/Frame.png"
                            className="object-fit-content mt-1"
                            alt=""
                          />
                          <div className="ml-2">
                            Exploit any bugs or glitches to gain an unfair
                            advantage.
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-start">
                          <img
                            src="./../images/Frame.png"
                            className="object-fit-content mt-1"
                            alt=""
                          />
                          <div className="ml-2">
                            Engage in disruptive behavior, including harassing
                            other players or using offensive language in public
                            chat.
                          </div>
                        </div>
                      </li>
                      <p className="  normal font__size--18 text__18-1024 color__gray-1 lh-2">
                        We do not condone any bullying behaviour, harassment or
                        inappropriate language with regards to race, sexual or
                        non sexual. Players will be immediately banned if there
                        is a breach in policy.
                      </p>
                    </ul>
                    <div className="mb-5">
                      <h4 className="semi-bold font__size--20 text__20-1024 mb-3">
                        4. In-Game Purchases
                      </h4>
                      <p className="  normal font__size--18 text__18-1024 color__gray-1 lh-2">
                        In-game purchases are final and non-refundable, except
                        where required by applicable law. Realm Riches reserves
                        the right to change the pricing or availability of
                        in-game content at any time.
                      </p>
                    </div>
                    <div className="mb-5">
                      <h4 className="semi-bold font__size--20 text__20-1024 mb-3">
                        5. Termination of License
                      </h4>
                      <p className="  normal font__size--18 text__18-1024 color__gray-1 lh-2">
                        We reserve the right to terminate your access to Realm
                        Riches at any time, with or without notice, if we
                        determine you have violated this Agreement.
                      </p>
                    </div>
                    <div className="mb-5">
                      <h4 className="semi-bold font__size--20 text__20-1024 mb-3">
                        6. User-Generated Content
                      </h4>
                      <p className="  normal font__size--18 text__18-1024 color__gray-1 lh-2">
                        By submitting content (e.g., character designs, custom
                        items) to Realm Riches, you grant us a perpetual,
                        worldwide, royalty-free license to use, reproduce,
                        modify, and distribute that content within the game.
                      </p>
                    </div>
                    <div className="mb-5">
                      <h4 className="semi-bold font__size--20 text__20-1024 mb-3">
                        7. Disclaimers
                      </h4>
                      <p className="  normal font__size--18 text__18-1024 color__gray-1 lh-2">
                        Realm Riches is provided "as is" without any warranties.
                        We do not guarantee uninterrupted or error-free
                        gameplay. To the extent allowed by law, we disclaim any
                        implied warranties of merchantability or fitness for a
                        particular purpose.
                      </p>
                    </div>
                    <div className="mb-5">
                      <h4 className="semi-bold font__size--20 text__20-1024 mb-3">
                        8. Limitation of Liability
                      </h4>
                      <p className="  normal font__size--18 text__18-1024 color__gray-1 lh-2">
                        In no event shall Realm Riches be liable for any
                        indirect, incidental, or consequential damages arising
                        out of your use of the game.
                      </p>
                    </div>
                    <div className="mb-5">
                      <h4 className="semi-bold font__size--20 text__20-1024 mb-3">
                        9. Governing Law
                      </h4>
                      <p className="  normal font__size--18 text__18-1024 color__gray-1 lh-2">
                        This Agreement is governed by the laws of the United
                        Arab Emirates. Any disputes shall be resolved in the
                        courts of the Federal Government of the United Arab
                        Emirates.
                      </p>
                    </div>
                    <div className="mb-5">
                      <h4 className="semi-bold font__size--20 text__20-1024 mb-3">
                        10. Amendments
                      </h4>
                      <p className="  normal font__size--18 text__18-1024 color__gray-1 lh-2">
                        We may amend this Licensing Agreement from time to time.
                        Any changes will be communicated through the game or via
                        email.
                      </p>
                    </div>
                    <div className="mb-5">
                      <h4 className="semi-bold font__size--20 text__20-1024 mb-3">
                        11. Contact Information
                      </h4>
                      <p className="  normal font__size--18 text__18-1024 color__gray-1 lh-2">
                        For any questions regarding this Licensing Agreement,
                        please contact us at{" "}
                        <a
                          href="mailto:support@realmriches.com"
                          target="_blank"
                        >
                          email support
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </Fragment>
  );
};

export default Privacy;
