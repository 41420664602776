import React, { useState, Fragment } from "react";

const Register = (props) => {
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordRe, setShowPasswordRe] = useState(false);
    return (
        <Fragment>
            <div
                class={
                    "wrapper__modal-wrap modal fade " + (props.modalRegister ? "show" : "")
                }
                id="exampleModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="close__wrap pointer d-lg-none mx-auto" onClick={() => props.onCLickModalRegister()}>
                    <img src="./../images/sdd.png" alt="" />
                </div>
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content position-relative">
                        <div className="close__wrap pointer d-none d-lg-flex" onClick={() => props.onCLickModalRegister()}>
                            <img src="./../images/sdd.png" alt="" />
                        </div>

                        <div className="text-center">
                            <img src="./../images/logo.svg" alt="" />
                        </div>
                        <h4 className="semi-bold font__size--32 text__32-1024 mt-4 mb-0">Create an Account</h4>
                        <hr className="line__hr" />

                        <div className="form-group">
                            <label htmlFor="" className="semi-bold font__size--14 text__14-1024">Email</label>
                            <div className="wrapper__field-wrap">
                                <input type="text" className="form-control medium font__size--16 text__16-1024" placeholder="Enter your email" />
                            </div>
                        </div>

                        <div className="form-group">
                            <label htmlFor="" className="semi-bold font__size--14 text__14-1024">Password</label>
                            <div className="wrapper__field-wrap d-flex align-items-center justify-content-between">
                                <input type={showPassword ? "password" : "text"} className="form-control medium font__size--16 text__16-1024" placeholder="Enter your password" />
                                <img src="./../images/eye-slash.png" className="pointer" onClick={() => setShowPassword(!showPassword)} alt="" />
                            </div>
                        </div>

                        <div className="form-group">
                            <label htmlFor="" className="semi-bold font__size--14 text__14-1024">Re-enter Password</label>
                            <div className="wrapper__field-wrap d-flex align-items-center justify-content-between">
                                <input type={showPasswordRe ? "password" : "text"} className="form-control medium font__size--16 text__16-1024" placeholder="Re-enter enter your password" />
                                <img src="./../images/eye-slash.png" className="pointer" onClick={() => setShowPasswordRe(!showPasswordRe)} alt="" />
                            </div>
                        </div>

                        <button className="btn btn__purple shadow color__white w-100 font__size--14 text__14-1024 medium rounded__12 position-relative z-2" onClick={(e) => props.onCLickSwitchModal("confirm")}>Sign up</button>

                        <div className="my-4 z-1 position-relative text-center wrapper__line-text">
                            <div className="text color__gray-1 d-inline-block medium font__size--16 text__16-1024">Or Continue with</div>
                        </div>

                        <a href="#!" className="btn btn__blue btn__sosmed-wrap fb">
                            <div className="d-flex align-items-center justify-content-center">
                                <img src="./../images/log (1).png" alt="" />
                                <span className="ml-2 medium font__size--14 text__14-1024 color__white">Continue with Facebook</span>
                            </div>
                        </a>
                        <a href="#!" className="btn btn__blue btn__sosmed-wrap google my-4">
                            <div className="d-flex align-items-center justify-content-center">
                                <img src="./../images/log (3).png" alt="" />
                                <span className="ml-2 medium font__size--14 text__14-1024 ">Continue with Google</span>
                            </div>
                        </a>
                        <a href="#!" className="btn btn__blue btn__sosmed-wrap apple">
                            <div className="d-flex align-items-center justify-content-center">
                                <img src="./../images/log (2).png" alt="" />
                                <span className="ml-2 medium font__size--14 text__14-1024 color__white">Continue with Apple</span>
                            </div>
                        </a>

                        <div className="mt-4 text-center medium font__size--14 text__14-1024">
                            Already have account? <span className="color__purple pointer" onClick={(e) => props.onCLickSwitchModal("login")}>Login</span>
                        </div>


                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Register;
