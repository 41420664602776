import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import CardCharacter from "../../component/Card/CardCharacter";
import Footer from "../../component/Other/Footer";
import SecBanner from "../../component/Page/SecBanner";
import SecBlog from "../../component/Page/SecBlog";

const Index = (props) => {
  const character = [
    "./../images/NorseGeneral.png",
    "./../images/ArabGeneral.png",
    "./../images/TechnoGeneral.png",
    "./../images/TikiGeneral.png",
  ];

  return (
    <Fragment>
      <div className="overflow-hidden">
        <div className="bg__blue-2">
          <section className="position-relative">
            <div className="container position-relative z-2">
              <div className="row">
                <div className="col-lg-6 images__head-top-home d-none d-lg-block">
                  <video
                    width="700"
                    height="700"
                    id="splash-video"
                    autoPlay
                    muted
                  >
                    <source
                      src="./../images/RRLogoVideo.mp4"
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <div className="col-lg-6 my-auto">
                  <h1 className="semi-bold font__size--72 text__70-1024 text__70-md text__70-sm mb-0">
                    Welcome to Realm Riches
                  </h1>
                  <p className="medium font__size--12 text__12-1024 color__gray-1 lh-2 my-4">
                    Experience the excitement of exploring a vast realms filled
                    with interesting characters and riches ready for plunder,
                    play in challenging puzzles and thrilling battles. Conquer
                    the obstacles and uncover the secrets of Realm Riches!
                  </p>

                  <a
                    href="#!"
                    className="d-inline-block semi-bold font__size--14 text__14-1024 btn btn__purple px-4 color__white shadow ml-auto text-capitalize rounded__12 mb-5"
                  >
                    <div className="d-flex align-items-center">
                      <img src="./../images/Game.png" className="mr-2" alt="" />
                      Play Free Button
                    </div>
                  </a>

                  <div className="d-flex flex-wrap flex-md-nowrap align-items-center pt-md-5">
                    <div className="wrapper__cicle-user d-flex align-items-center mb-3 mb-md-0">
                      <div className="items">
                        <img src="./../images/robertcow.png" alt="" />
                      </div>
                      <div className="items">
                        <img src="./../images/rubdub2024.png" alt="" />
                      </div>
                      <div className="items">
                        <img src="./../images/baldbob82.png" alt="" />
                      </div>
                      <div className="items">
                        <img src="./../images/lalala12.png" alt="" />
                      </div>
                      <div className="items">
                        <img src="./../images/DaringDannyX.png" alt="" />
                      </div>
                      <div className="items">
                        <img src="./../images/greekmush22.png" alt="" />
                      </div>
                    </div>
                    <span className="ml-2 semi-bold font__size--20 text__20-1024">
                      86K+ Subscribers
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <img
          src="./../images/Subtract.png"
          className="w-100 img-cover"
          alt=""
        />

        <section className="position-relative">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 position__initial">
                <img
                  src="./../images/NorseEgypt.png"
                  className="images__about-left"
                  alt=""
                />
              </div>
              <div className="col-lg-6">
                <h2 className="semi-bold font__size--72 text__70-1024 text__70-md text__70-sm mb-4">
                  Let the raids begin!
                </h2>
                <p className="medium font__size--12 text__12-1024 color__gray-1 lh-2">
                  Play the challenging levels in the realms and progress with
                  wealth and experience. Build your empire to conquer more
                  levels, raid opposing threats to your own realm and build a
                  mighty empire.{" "}
                </p>
                <p className="medium font__size--12 text__12-1024 color__gray-1 mb-4 lh-2">
                  Choose your friends and foes wisely. Each realm has a general
                  with special abilities which can either help or hinder you
                  depending on how you ally with them.{" "}
                </p>

                <NavLink
                  to="/about"
                  className="d-inline-block semi-bold font__size--16 text__16-1024 btn btn__purple px-4 color__white shadow ml-auto text-capitalize rounded__12"
                >
                  Learn The Game
                </NavLink>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="d-flex align-items-center justify-content-center justify-content-md-between mb-5">
              <h2 className="semi-bold font__size--48 text__48-1024 text__48-md text__48-sm text__48-xx">
                Choose Your General!
              </h2>
              <NavLink
                to="/characters"
                className="medium font__size--14 text__14-1024 btn btn__outlined--purple color__purple no__opacity h__white shadow ml-auto text-capitalize rounded__12 d-none d-md-block"
              >
                See All Generals
              </NavLink>
            </div>

            <div className="row">
              {character.map((obj) => {
                return (
                  <div className="col-md-6 col-lg-3 mb-4 mb-lg-0">
                    <CardCharacter data={obj} />
                  </div>
                );
              })}
            </div>

            <div className="text-center">
              <NavLink
                to="/characters"
                className="medium font__size--14 text__14-1024 btn btn__outlined--purple color__purple no__opacity h__white shadow ml-auto text-capitalize rounded__12 d-md-none"
              >
                See All Characters
              </NavLink>
            </div>
          </div>
        </section>

        <section className="position-relative">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 order-lg-1 order-12">
                <h2 className="semi-bold font__size--64 text__65-1024 text__65-md text__65-sm mb-4">
                  Get Ready for an Epic Gaming Adventure!
                </h2>
                <p className="medium font__size--12 text__12-1024 color__gray-1 lh-2">
                  Realm riches is the ultimate quest for coins and wealth.
                  Progress your characters with special abilities, protection
                  and attack strength to make your raids and defences against
                  raids more effective. Play as leader in one of 7 different
                  realms, each with unique characteristics and abilities to help
                  you in this epic game adventure.
                </p>

                <a
                  href="#!"
                  className="d-inline-block semi-bold font__size--16 text__16-1024 btn btn__purple px-4 color__white shadow ml-auto text-capitalize rounded__12"
                >
                  <div className="d-flex align-items-center">
                    <img src="./../images/Game.png" className="mr-2" alt="" />
                    Play Free
                  </div>
                </a>
              </div>
              <div className="col-lg-6 order-1 order-lg-12 position__initial">
                <img
                  src="./../images/GreekGeneralCoins.png"
                  className="images__about-right"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="text-center mb-5">
              <h2 className="semi-bold font__size--72 text__70-1024 text__70-md text__70-sm mb-0">
                The Realms
              </h2>
            </div>

            <div className="row mb-4 wrapper__row-padding justify-content-center">
              <div className="items col-md-6 mb-4 mb-lg-0 col-lg-4">
                <div className="wrapper__card-map text-center">
                  <img
                    src="./../images/TechnoRealmMap.png"
                    className="img left mb-3"
                    alt=""
                  />
                  <h5 className="semi-bold font__size--24 text__24-1024">
                    Techno Realm
                  </h5>
                </div>
              </div>
              <div className="items col-md-6 mb-4 mb-lg-0 col-lg-4">
                <div className="wrapper__card-map text-center">
                  <img
                    src="./../images/NorseRealmMap.png"
                    className="img right mb-3"
                    alt=""
                  />
                  <h5 className="semi-bold font__size--24 text__24-1024">
                    Norse Realm
                  </h5>
                </div>
              </div>
              <div className="items col-md-6 mb-4 mb-lg-0 col-lg-4">
                <div className="wrapper__card-map text-center">
                  <img
                    src="./../images/TikiRealmMap.png"
                    className="img left mb-3"
                    alt=""
                  />
                  <h5 className="semi-bold font__size--24 text__24-1024">
                    Tiki Realm
                  </h5>
                </div>
              </div>
            </div>

            <div className="text-center">
              <NavLink
                to="/maps"
                className="d-inline-block semi-bold font__size--16 text__16-1024 text__16-1024 btn btn__purple px-4 color__white shadow ml-auto text-capitalize rounded__12 mb-5"
              >
                View All Maps
              </NavLink>
            </div>
          </div>
        </section>

        <SecBlog title={"Latest News"} showMore={true} />

        <SecBanner />

        <Footer />
      </div>
    </Fragment>
  );
};

export default Index;
