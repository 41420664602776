import React from 'react'

const SecBanner = () => {
    return (
        <section>
            <div className="container">
                <div className="wrapper__banner-gamgam position-relative">
                    <div className="row">
                        <div className="col-md-6 z-2 text-center text-md-left">
                            <h2 className='semi-bold font__size--64 text__65-1024 text__65-md text__65-sm color__white mb-0'>Subscribe for the Mobile App</h2>
                            <p className='medium font__size--12 text__12-1024 color__white my-4'>We are working on the Realm Riches Mobile App and it will be released in 2025, to hear updates about development - register for more information.</p>
                            <div className="d-flex justify-content-center justify-content-md-start">
                                <a href="#!">
                                    <img src="./../images/safdd (2).png" alt="" />
                                </a>
                                <a href="#!" className='ml-2'>
                                    <img src="./../images/App Store.png" alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <img src="./../images/NorseBannerBubbles.png" className='images__banner-right' alt="" />
                </div>
            </div>
        </section>
    )
}

export default SecBanner