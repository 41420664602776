import React from 'react'
import { NavLink } from 'react-router-dom'

const CardCharacter = (props) => {
    return (
        <div className='wrapper__card-character position-relative'>
            <div className="character">
                <img src={props.data} alt="" />
            </div>
            <NavLink to="/characters" className='semi-bold font__size--14 text__14-1024 btn btn__purple color__white shadow ml-auto text-capitalize rounded__50'>See Detail</NavLink>
        </div>
    )
}

export default CardCharacter