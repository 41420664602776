import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import Footer from "../../component/Other/Footer";
import Head from "../../component/Page/Head";

const Leaderboard = () => {
  const data = [
    {
      img: "./../images/DaringDannyX.png",
      name: "DaringDannyX",
      star: 5,
      point: 83342,
    },
    {
      img: "./../images/lalala12.png",
      name: "lalala12",
      star: 5,
      point: 83342,
    },
    {
      img: "./../images/robertcow.png",
      name: "robertcow",
      star: 5,
      point: 83342,
    },
    {
      img: "./../images/Andrearial.png",
      name: "andreariel",
      star: 5,
      point: 83342,
    },
    {
      img: "./../images/kyle08.png",
      name: "Kyle08",
      star: 5,
      point: 83342,
    },
    {
      img: "./../images/nananad.png",
      name: "nananad",
      star: 5,
      point: 83342,
    },
    {
      img: "./../images/kingtut.png",
      name: "kingtut1989",
      star: 4,
      point: 83342,
    },
    {
      img: "./../images/pudlove77.png",
      name: "Pudlove77",
      star: 4,
      point: 83342,
    },
    {
      img: "./../images/rubdub2024.png",
      name: "rubdub2024",
      star: 3,
      point: 83342,
    },
    {
      img: "./../images/baringtons22.png",
      name: "baringtons22",
      star: 3,
      point: 83342,
    },
    {
      img: "./../images/greekmush22.png",
      name: "GreekMush22",
      star: 3,
      point: 83342,
    },
    {
      img: "./../images/baldbob82.png",
      name: "Baldbob82",
      star: 2,
      point: 83342,
    },
  ];

  const showStar = (e) => {
    const save = 5 - e;
    const star = [];

    for (let index = 0; index < e; index++) {
      star.push(<img src="./../images/Stars.png" alt="" />);
    }

    if (save > 0) {
      for (let index = 0; index < save; index++) {
        star.push(
          <img src="./../images/Stars.png" className="opacity__4" alt="" />
        );
      }
    }

    return star;
  };
  return (
    <Fragment>
      <div className="overflow-hidden">
        <div className="bg__blue-2 header-leaderboard">
          <img
            src="./../images/leaderBanner.webp"
            className="images__head-top"
            alt=""
          />
          {/* added this for the leaderboard image and not sure which class will centre */}
        </div>
        <img
          src="./../images/Subtract.png"
          className="w-100 img-cover"
          alt=""
        />

        <section>
          <div className="container">
            <div className="wrapper__offset-table">
              <Head title={"Leaderboards"} />
              <div className="auto">
                <div className="row px-5 semi-bold font__size--24  mb-5">
                  <div className="col-2">Rank</div>
                  <div className="col-3">Username</div>
                  <div className="col-4">Stars</div>
                  <div className="col-3">Game Point</div>
                </div>
                {data.map((obj, i) => {
                  return (
                    <NavLink
                      to="/player/detail"
                      className="wrapper__card-poin d-inline-block w-100 color__black mb-4"
                    >
                      <div className="row">
                        <div className="my-auto col-2 semi-bold number">
                          {i <= 9 ? "0" : ""}
                          {i + 1}
                        </div>
                        <div className="my-auto col-3">
                          <div className="d-flex align-items-center user">
                            <div className="position-relative flex-shrink-0">
                              {i == 0 ? (
                                <img
                                  src="./../images/Crown.png"
                                  className="win"
                                  alt=""
                                />
                              ) : (
                                ""
                              )}
                              <img src={obj.img} alt="" />
                            </div>
                            <div className="ml-3 semi-bold font__size--24 ">
                              {obj.name}
                            </div>
                          </div>
                        </div>
                        <div className="my-auto col-4">
                          <div className="wrapper__star">
                            {showStar(obj.star)}
                          </div>
                        </div>
                        <div className="my-auto col-3">
                          <div className="d-flex align-items-center semi-bold font__size--24 ">
                            <img src="./../images/dollar (1) 1.png" alt="" />
                            <span className="ml-2">{obj.point}</span>
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  );
                })}
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </Fragment>
  );
};

export default Leaderboard;
