import React from 'react'

const AccordionFaq = (props) => {
    return (
        <div className={"wrapper__accordion-faq " + (props.toogle == props.id ? "active" : "")}>
            <div className="head pointer" onClick={() => props.toogleFunc(props.id)}>
                <div className="d-flex align-items-center justify-content-between">
                    <h4 className='mb-0 semi-bold font__size--20 text__20-1024'>
                        {props.data.title}
                    </h4>

                    <div className="arrow ml-2 flex-shrink-0 position-relative">
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </div>
            <div className="line"></div>
            <div className="desc">
                <p className='mb-0 medium font__size-12 text__12-1024 color__gray-1'>
                    {props.data.desc}
                </p>
            </div>
        </div>
    )
}

export default AccordionFaq