import React, { Fragment } from "react";
import Footer from "../../component/Other/Footer";
import Head from "../../component/Page/Head";

const Terms = () => {
  return (
    <Fragment>
      <div className="overflow-hidden">
        <div className="bg__blue-2">
          <Head title={"Terms of Service"} />
        </div>
        <img
          src="./../images/Subtract.png"
          className="w-100 img-cover"
          alt=""
        />
        <section>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-10">
                <div className="wrapper__text-wrap">
                  <div className="mb-5">
                    <h2 className="semi-bold font__size--48 text__48-1024 text__48-md text__48-sm">
                      General Information
                    </h2>
                    <p className="normal font__size--18 text__18-1024 color__gray-1 lh-2">
                      Welcome to Realm Riches! By accessing or using Realm
                      Riches (the “Game”), you agree to comply with and be bound
                      by these Terms of Service (“Terms”). If you do not agree
                      to these Terms, please do not use the Game.
                    </p>
                  </div>

                  <div className="mb-5">
                    <h3 className="semi-bold font__size--32 text__32-1024 text__32-xs">
                      1. Terms
                    </h3>
                    <p className="normal font__size--18 text__18-1024 color__gray-1 lh-2">
                      You must be at least 13 years old to create an account and
                      use Realm Riches. If you are under the age of majority in
                      your jurisdiction, you must have your parent or guardian’s
                      permission to use the Game.
                    </p>
                  </div>

                  <div className="mb-5">
                    <h3 className="semi-bold font__size--32 text__32-1024 text__32-xs">
                      2. User License
                    </h3>
                    <p className="normal font__size--18 text__18-1024 color__gray-1 lh-2">
                      We grant you a limited, non-exclusive, non-transferable,
                      revocable license to access and use the Game for your
                      personal entertainment purposes. All content within the
                      Game, including Virtual Items, is owned by Realm Riches or
                      its licensors.
                    </p>

                    <ul className="normal font__size--18 text__18-1024 color__gray-1 pl-4">
                      <li>
                        Virtual Items have no real-world value and cannot be
                        exchanged for real currency.
                      </li>
                      <li>
                        You agree not to reverse-engineer, modify, or disrupt
                        the Game.
                      </li>
                      <li>
                        All in-game purchases are final and non-refundable,
                        except where required by law.
                      </li>
                      <li>
                        We reserve the right to terminate your account if you
                        engage in prohibited behavior.
                      </li>
                    </ul>

                    <p className="normal font__size--18 text__18-1024 color__gray-1 lh-2">
                      By using Realm Riches, you acknowledge and agree that your
                      account, Virtual Items, and any content you post are
                      subject to these Terms and the Game's policies.
                    </p>
                  </div>

                  <div className="mb-5">
                    <h3 className="semi-bold font__size--32 text__32-1024 text__32-xs">
                      3. User Conduct
                    </h3>
                    <p className="normal font__size--18 text__18-1024 color__gray-1 lh-2">
                      You agree not to use the Game for any illegal or
                      unauthorized purposes, nor to engage in activities that
                      disrupt the experience of other players. This includes but
                      is not limited to cheating, using unauthorized third-party
                      software, or exploiting bugs.
                    </p>
                  </div>

                  <div className="mb-5">
                    <h3 className="semi-bold font__size--32 text__32-1024 text__32-xs">
                      4. Termination of Service
                    </h3>
                    <p className="normal font__size--18 text__18-1024 color__gray-1 lh-2">
                      We reserve the right to suspend or terminate your account
                      at any time, with or without notice, if we determine that
                      you have violated these Terms or engaged in behavior
                      harmful to the Game or its players.
                    </p>
                  </div>

                  <div className="mb-5">
                    <h3 className="semi-bold font__size--32 text__32-1024 text__32-xs">
                      5. Limitation of Liability
                    </h3>
                    <p className="normal font__size--18 text__18-1024 color__gray-1 lh-2">
                      Realm Riches is provided on an "as is" and "as available"
                      basis. We make no warranties, whether express or implied,
                      regarding the Game. To the extent permitted by law, we
                      disclaim any implied warranties of merchantability,
                      fitness for a particular purpose, or non-infringement.
                    </p>
                  </div>

                  <div className="">
                    <h3 className="semi-bold font__size--32 text__32-1024 text__32-xs">
                      6. Changes to These Terms
                    </h3>
                    <p className="normal font__size--18 text__18-1024 color__gray-1 lh-2">
                      We may update these Terms from time to time. When we make
                      changes, we will notify you by revising the effective date
                      at the top of these Terms. Your continued use of the Game
                      after the changes are made constitutes your acceptance of
                      the new Terms.
                    </p>
                  </div>

                  <div className="mb-5">
                    <h3 className="semi-bold font__size--32 text__32-1024 text__32-xs">
                      7. Contact Information
                    </h3>
                    <p className="normal font__size--18 text__18-1024 color__gray-1 lh-2">
                      For any questions regarding these terms, please contact us
                      at{" "}
                      <a href="mailto:support@realmriches.com" target="_blank">
                        email support
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </Fragment>
  );
};

export default Terms;
