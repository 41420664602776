import React, { Fragment } from "react";
import Footer from "../../component/Other/Footer";
import SecBlog from "./../../component/Page/SecBlog";

const DetailNews = () => {
  return (
    <Fragment>
      <div className="overflow-hidden">
        <div className="bg__blue-2">
          <section className="position-relative">
            <div className="container text-center">
              <div className="text-center">
                <p className="semi-bold font__size--18 text__18-1024 color__blue">
                  Game Update | 25 Jun 2024
                </p>
                <h3 className="normal font__size--54 text__50-1024 text__50-sm text__50-xs mb__min-9">
                  Realm Riches will take social{" "}
                  <br className="d-none d-lg-block" /> gameplay to the next
                  level
                </h3>
              </div>
            </div>
          </section>
        </div>
        <img
          src="./../images/Subtract.png"
          className="w-100 img-cover"
          alt=""
        />

        <section className="pt-0 pt-sm-5">
          <div className="container">
            <img
              src="./../images/NewsArticle001.png"
              className="images__wrap-featured mb-5"
              alt=""
            />

            <div className="position-relative">
              <ul className="wrapper__sosmed-list d-none d-md-block">
                <li>
                  <a href="#!">
                    <img src="./../images/1.png" alt="" />
                  </a>
                </li>
                <li>
                  <a href="#!">
                    <img src="./../images/2.png" alt="" />
                  </a>
                </li>
                <li>
                  <a href="#!">
                    <img src="./../images/3.png" alt="" />
                  </a>
                </li>
                <li>
                  <a href="#!">
                    <img src="./../images/4.png" alt="" />
                  </a>
                </li>
              </ul>
              <div className="row justify-content-center">
                <div className="col-md-10 col-lg-7">
                  <p className="medium font__size--12 text__12-1024 lh-2 mb-4">
                    The team behind Realm Riches is hard at work bringing a
                    truly engaging and innovative gaming experience to players
                    worldwide. With a focus on blending captivating gameplay
                    mechanics and advanced technology, Realm Riches promises to
                    offer something for every type of player.{" "}
                  </p>
                  {/*<p className='medium font__size--12 text__12-1024 lh-2 mb-4'>Maecenas in pharetra hendrerit neque, tellus eu. Arcu tempus, vel blandit adipiscing a sed cursus. Augue vestibulum tempus lectus gravida condimentum mauris iaculis. Sodales imperdiet id maecenas molestie id.</p>*/}

                  <div className="wrapper__side-line mb-4">
                    <p className="mb-0 medium font__size--18 text__18-1024 lh-2">
                      “So what exactly is Realm Riches and what does it do? In
                      simple terms, Realm Riches is a revolutionary social
                      gaming platform where you can play, ally and attack
                      opponents for coins, gems and other riches.”
                    </p>
                  </div>

                  <p className="medium font__size--12 text__12-1024 lh-2 mb-4">
                    One of the standout features of the game is the integration
                    of AI-driven systems designed to provide challenging and
                    dynamic gameplay. This AI adjusts to individual player
                    strategies, ensuring that each session feels fresh and
                    unpredictable. Players can engage in exciting mini-games,
                    such as Spin the Wheel, Attack Raid, and Defend, each adding
                    a unique layer of strategy and fun to the core gameplay
                    experience.{" "}
                  </p>
                  <p className="medium font__size--12 text__12-1024 lh-2 mb-4">
                    To further enhance security and player trust, Realm Riches
                    leverages secure blockchain technology to store all player
                    wins and activity logs. This ensures that every victory and
                    leaderboard position is tamper-proof, giving players peace
                    of mind as they compete for top spots.
                  </p>
                  <p className="medium font__size--12 text__12-1024 lh-2 mb-4">
                    Speaking of leaderboards, Realm Riches offers monthly prizes
                    for the highest-ranking players, adding an extra incentive
                    to refine your strategy and engage in battles. These rewards
                    are carefully crafted to keep the competition fierce and
                    fun, whether you're taking part in a raid or fortifying your
                    defences.
                  </p>
                  <p className="medium font__size--12 text__12-1024 lh-2 mb-4">
                    With every detail meticulously crafted, Realm Riches is
                    poised to be a game that not only entertains but also
                    challenges, while ensuring a secure and fair experience for
                    all. Keep an eye out for updates as this exciting title
                    moves closer to launch!
                  </p>
                  {/*} <ul className='list__detail-news medium font__size--12 text__12-1024 pl-3'>
                                        <li>Id pellentesque ut pellentesque varius amet mauris. </li>
                                        <li>Tempor, risus, congue gravida nulla tincidunt. </li>
                                        <li>Tincidunt magnis eu, vitae dictumst. </li>
                                        <li>Aenean dictumst risus posuere a at id fermentum nibh.</li>
                                    </ul>*/}

                  <img
                    src="./../images/NewsUserTesting001.png"
                    className="images__desc-news mb-4"
                    alt=""
                  />

                  <p className="medium font__size--12 text__12-1024 lh-2 mb-4">
                    Currently, Realm Riches is undergoing extensive play testing
                    to ensure that the final product delivers the most enjoyable
                    and fair experience possible. Our dedicated team is working
                    closely with select players to gather feedback, fine-tune
                    the gameplay, and balance the AI mechanics for maximum fun
                    and competitive fairness.{" "}
                  </p>
                  <p className="medium font__size--12 text__12-1024 lh-2 mb-4">
                    These play tests are crucial in perfecting the mini-games
                    and leaderboard system, ensuring that every player, from
                    casual gamers to competitive strategists, can fully enjoy
                    the platform. With these enhancements in place, we're
                    excited to announce that Realm Riches will be available for
                    public release by mid-2025, offering a truly next-generation
                    gaming experience.
                  </p>
                  <p className="medium font__size--12 text__12-1024 lh-2 mb-4">
                    If you are interested in becoming a live play tester of the
                    platform, signup up for the beta program.{" "}
                  </p>
                </div>
              </div>

              {/* <ul className="wrapper__sosmed-list d-md-none d-block">
                                <li>
                                    <a href="#!"><img src="./../images/1.png" alt="" /></a>
                                </li>
                                <li>
                                    <a href="#!"><img src="./../images/2.png" alt="" /></a>
                                </li>
                                <li>
                                    <a href="#!"><img src="./../images/3.png" alt="" /></a>
                                </li>
                                <li>
                                    <a href="#!"><img src="./../images/4.png" alt="" /></a>
                                </li>
                            </ul> */}
            </div>
          </div>
        </section>

        <SecBlog title={"Relate News"} />

        <Footer />
      </div>
    </Fragment>
  );
};

export default DetailNews;
