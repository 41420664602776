import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import Footer from "../../component/Other/Footer";
import Head from "../../component/Page/Head";
import SecBanner from "../../component/Page/SecBanner";

const DetailMaps = () => {
  const data = [
    {
      img: "./../images/DaringDannyX.png",
      name: "DaringDannyX",
      star: 5,
      point: 83342,
    },
    {
      img: "./../images/lalala12.png",
      name: "lalala12",
      star: 5,
      point: 83342,
    },
    {
      img: "./../images/robertcow.png",
      name: "robertcow",
      star: 5,
      point: 83342,
    },
    {
      img: "./../images/Andrearial.png",
      name: "andreariel",
      star: 5,
      point: 83342,
    },
    {
      img: "./../images/kyle08.png",
      name: "kyle08",
      star: 5,
      point: 83342,
    },
  ];

  const showStar = (e) => {
    const save = 5 - e;
    const star = [];

    for (let index = 0; index < e; index++) {
      star.push(<img src="./../images/Stars.png" alt="" />);
    }

    if (save > 0) {
      for (let index = 0; index < save; index++) {
        star.push(
          <img src="./../images/Stars.png" className="opacity__4" alt="" />
        );
      }
    }

    return star;
  };
  return (
    <Fragment>
      <div className="overflow-hidden">
        <div className="bg__blue-2">
          <Head title={"Detail Maps"} />
        </div>
        <img
          src="./../images/Subtract.png"
          className="w-100 img-cover"
          alt=""
        />

        <section>
          <div className="container">
            <div className="row">
              <div className="col-md-6 text-center mb-4 my-md-auto">
                <img
                  src="./../images/HeroNorseRealm.png"
                  className="images__maps"
                  alt=""
                />
              </div>
              <div className="col-md-6 my-auto">
                <h2 className="semi-bold font__size--54 text__50-1024 text__50-sm text__50-xs mb-4">
                  The Norse Realm
                </h2>
                <p className="medium font__size--12 text__12-1024 color__gray-1 lh-2">
                  Enter the rugged and untamed land of the Norse, where the
                  ancient gods of Asgard reign and fierce warriors tread. This
                  is a realm shaped by the raw forces of nature, a land of icy
                  tundras, towering mountains, vast fjords, and endless
                  flatlands. With each step, you’ll uncover the heart of Norse
                  mythology, with echoes of Odin, Thor, and Loki resonating
                  through the landscape. The elements are harsh, but the rewards
                  for bravery are legendary.
                </p>
                <p className="medium font__size--12 text__12-1024 color__gray-1 lh-2 mb-4">
                  In this realm, players will experience the essence of Norse
                  culture and mythology through a diverse and captivating
                  landscape. As they traverse through the unforgiving tundra,
                  climb the mystical mountains, sail the legendary fjords, and
                  cross the expansive flatlands, they’ll come face to face with
                  the spirit of the Norse gods and their extraordinary legacy.
                </p>
                <a
                  href="#!"
                  className="d-inline-block semi-bold font__size--16 text__16-1024 btn btn__purple px-4 color__white shadow ml-auto text-capitalize rounded__12"
                >
                  <div className="d-flex align-items-center">
                    <img src="./../images/Game.png" className="mr-2" alt="" />
                    Play Free
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="position-relative">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 order-lg-1 order-12">
                <h2 className="semi-bold font__size--64 text__65-1024 text__65-md text__65-sm mb-4">
                  The Norse are cunning raiders!
                </h2>
                <p className="medium font__size--12 text__12-1024 color__gray-1 lh-2">
                  Experience the excitement of exploring a vast world filled
                  with interesting characters, challenging puzzles and thrilling
                  battles. Conquer the obstacles and uncover the secrets of
                  Realm Riches – the ultimate gaming experience!
                </p>

                <a
                  href="#!"
                  className="d-inline-block semi-bold font__size--16 text__16-1024 btn btn__purple px-4 color__white shadow ml-auto text-capitalize rounded__12"
                >
                  <div className="d-flex align-items-center">
                    <img src="./../images/Game.png" className="mr-2" alt="" />
                    Play Free
                  </div>
                </a>
              </div>
              <div className="col-lg-6 order-1 order-lg-12 position__initial">
                <img
                  src="./../images/NorseLogo.png"
                  className="images__about-right"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            {/*<h2 className='semi-bold font__size--48 text__48-1024 text__48-md text__48-sm text__48-xx mb-3'>Featured News</h2>*/}
            <NavLink to="#" className="color__black d-inline-block w-100">
              <h4 className="normal font__size--32 text__32-1024 text__32-xs text__32-xxs">
                Norse Realm Levels Map
              </h4>
              <p className="semi-bold font__size--18 text__18-1024 color__blue mb-2">
                Game Update | 12 Aug 2024
              </p>
              <img
                src="./../images/NorseRealmLevelMap.png"
                className="images__wrap-featured mb-3"
                alt=""
              />
            </NavLink>
          </div>
        </section>
        <section className="pb-0">
          <div className="container">
            <div className="wrapper__offset-table">
              <div className="auto">
                <div className="text-center mb-5">
                  <h2 className="semi-bold font__size-60 text__60-1024 text__60-sm text__60-xxs">
                    Leaderboards
                  </h2>
                </div>
                {data.map((obj, i) => {
                  return (
                    <NavLink
                      to="/player/detail"
                      className="wrapper__card-poin d-inline-block w-100 color__black mb-4"
                    >
                      <div className="row">
                        <div className="my-auto col-2 semi-bold number">
                          {i <= 9 ? "0" : ""}
                          {i + 1}
                        </div>
                        <div className="my-auto col-3">
                          <div className="d-flex align-items-center user">
                            <div className="position-relative flex-shrink-0">
                              {i == 0 ? (
                                <img
                                  src="./../images/Crown.png"
                                  className="win"
                                  alt=""
                                />
                              ) : (
                                ""
                              )}
                              <img src={obj.img} alt="" />
                            </div>
                            <div className="ml-3 semi-bold font__size--24 ">
                              {obj.name}
                            </div>
                          </div>
                        </div>
                        <div className="my-auto col-4">
                          <div className="wrapper__star">
                            {showStar(obj.star)}
                          </div>
                        </div>
                        <div className="my-auto col-3">
                          <div className="d-flex align-items-center semi-bold font__size--24 ">
                            <img src="./../images/dollar (1) 1.png" alt="" />
                            <span className="ml-2">{obj.point}</span>
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  );
                })}
              </div>
            </div>
          </div>
        </section>

        <SecBanner />

        <Footer />
      </div>
    </Fragment>
  );
};

export default DetailMaps;
